import React from "react";
import { Badge } from "antd";
const Nocomprometidos = () => {
  return (
    <Badge.Ribbon text=" Colaborador No Comprometido" color="red">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Colaborador No Comprometido */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Colaborador No Comprometido
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Las personas que se encuentran en este rubro tienden a ser las
            personas que tienen una visión negativa de la organización. Tienden
            a cometer más errores en sus labores, a tener actitudes negativas, y
            a romper las normativas de la institución.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Cuando la persona presenta estos tres rasgos al mismo tiempo, se
            debe a que ha sido un proceso progresivo en dónde hubo situaciones
            en el trabajo que fueron desgastando la relación entre el
            colaborador y la organización.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Es importante recalcar que, para alcanzar esta condición, el
            colaborador perdió la confianza en su gerente o supervisor.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - En el contexto de esta prueba, un colaborador no comprometido
            cuenta con:
            <ul
              style={{
                listStyleType: "circle",
                paddingLeft: "40px",
                marginTop: "5px",
              }}
            >
              <li>Nivel alto de cansancio emocional</li>
              <li>Nivel alto de despersonalización</li>
              <li>Nivel bajo de realización personal</li>
            </ul>
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Evaluar la relación entre los colaboradores y sus supervisores
            para entender cuál es la dinámica que se está dando en el equipo.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Buscar posibles cambios de puesto, equipo, liderazgo o
            departamento para recobrar la confianza del colaborador con la
            institución.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Desarrollar programas para comunicación interna que permitan a los
            colaboradores expresar sus sentimientos de manera anónima y que sean
            atendidos.
          </li>
          <li>
            - Continuar monitoreando a los colaboradores, pues existe una
            posibilidad de renuncia.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Nocomprometidos;
