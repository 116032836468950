import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import{
Drawer,
Card,
Space,
Table,
Tag,
Tooltip,
Button,
Skeleton,
Cascader,
Col,
Row,
Input
} from 'antd';
import 'antd/dist/reset.css';
import Api from '../../../Api'
import { gql, useQuery } from '@apollo/client'
import { 
    UserOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SearchOutlined
} from '@ant-design/icons';
import Swal from 'sweetalert2';
import {
  QUERY_STRING_GET_EMPRESAS
} from '../../../gpl/mantenimientoEmpresas';
import '../Mantenimientos.css'
import EditarEmpleado from "./EditarEmpleado";
import { tokenDatos } from '../../../conf/token';
import Highlighter from 'react-highlight-words';

//import DataEncuesta from "./DataEncuesta";

const GET_EMPRESAS = gql`${QUERY_STRING_GET_EMPRESAS}`;

const ListaEmpleados = () => {
     
  const formRef = React.useRef(null);
  const [openEditar, setOpenEditar] = useState(false);
  const [dataLogin, setDataLogin] = useState(tokenDatos())
  const [idEnc, setidEnc] = useState(0);
  const [idEmpleado, setIdEmpleado] = useState(0)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState([])
  const [editingKey, setEditingKey] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  
  //const [dataEmpresas, setDataEmpresas] = useState([]);
     //const { loading, data, refetch } = useQuery(gql);
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const cargarData = async () => {
    const postUser_query = `
        mutation {
            empresaallEnc(input: {ID: ${dataLogin.CODIGOEMPRESA}}) {
            EMP
            ENCNO
            FECFIN
            FECHA
            FECIN
            ID
            STATUS
            }
        }
      `;

    const postUser_values = {
        "input": {
            "ID": `${String(dataLogin.CODIGOEMPRESA)}`,
        }
    };
    const response = await Api.getInstance().executeMutation(postUser_query, postUser_values)
    let op = [];
    
    if( response.empresaallEnc.length > 0){
        let value = ''
        response.empresaallEnc.map((item) => (
           op.push(
            {
                value: item.ENCNO,
                label: `Encuesta ${item.ENCNO}`
            }
           )
           
        ))
        
        setOptions(op)
        setLoading(false)
    }

  }
  const CloseDrawer = () => {
    //console.log('entro en close')
    setOpenEditar(false);
    onChange(idEnc);
  }
  const tipoTag = (record) => {
    //console.log(record)
      if (record.estado === '1' ) {
        return (
            <Tag key = {record.id} icon={<CloseCircleOutlined />} color="error">
                Inactivo
            </Tag>
        )
      }
      if (record.estado === '0') {
          return(
              <Tag key = {record.id} icon={<CheckCircleOutlined />} color="success">
                  Activo
              </Tag>
          )
      }
  
  }
  const AbrirVerEditar = async (record) => {
    //console.log(record.ID)
    setIdEmpleado(record.ID)
    setOpenEditar(true);
  };
  const VerEncuesta = async () => {
    try {
    /*  const postUser_query = `mutation($input:inputColaborador){
                                  postColaborador(input:$input){
                                  codigo
                                  mensaje
                                  detalle
                                  id
                                  }
                              }`;

      const postUser_values = {
          "input": {
              "idTipoColaborador": poseeEmpresa ? 1 : 2,
              "idNegocio": poseeEmpresa ? idAliado : 0,
              "nombre": nombresColaborador,
              "apellido": apellidosColaborador,
              "telefono": telefonoColaborador,
              "dpi": dpiColaborador,
              "idTipoPago": idFormaPagostate,
              "idBanco": idBancoPago,
              "idCuenta": idTipoCuenta,
              "numeroCuenta": numeroCuenta,
              "nombreCuenta": nombreCuenta
          }
      }*/

      //const response = await Api.getInstance().executeMutation(postUser_query, postUser_values)
      //const { postColaborador } = response

      /*if (parseInt(postColaborador[0].codigo) === 1) {
          setStatusBoton(false);
          Swal.fire({
              title: postColaborador[0].mensaje,
              text: postColaborador[0].detalle,
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Aceptar'
          }).then((result) => {
              if (result.isConfirmed) {
                  history.push(`${SERVER_ROUTE}/aliados/colaboradores`)
              }
          })
      } else {
          setStatusBoton(false);
          Swal.fire(
              postColaborador[0].mensaje,
              postColaborador[0].detalle,
              'error'
          );
      }*/
      Swal.fire({
        title: 'Información',
        text: 'Empresa inactivada exitosamente',
        icon: 'success',
        confirmButtonColor: '#ff8f00',
        confirmButtonText: 'Ok'
    })


  } catch (e) {
      console.log(e)
      Swal.fire({
          title: 'Error',
          text: 'Error al inactivar empresa',
          icon: 'error',
          confirmButtonText: 'Ok'
      })
  }

  
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Nuevo
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: 'Acción',
      dataIndex: 'ID',
      key: 'ID',
      
    
      render: (text, record) => (
        <Space >
          <Tooltip title="Modificar empleado">
              <Button icon={<UserOutlined />} onClick={() => AbrirVerEditar(record)}>Modificar</Button> 
          </Tooltip>
        </Space>
      ),
    },

    {
        title: 'Nombre',
        dataIndex: 'NOMBRE',
        key: 'NOMBRE',  
        ...getColumnSearchProps('NOMBRE'),
    },
    {
        title: 'Email',
        dataIndex: 'EMAILEMP',
        key: 'EMAILEMP', 
        width: 50,
        ...getColumnSearchProps('EMAILEMP'),
    },
    {
        title: 'Código empleado',
        dataIndex: 'CODEMP',
        key: 'CODEMP', 
        ...getColumnSearchProps('CODEMP'),
      
    },
    {
        title: 'Antiguedad',
        dataIndex: 'ANTIGUEDAD',
        key: 'ANTIGUEDAD', 
        ...getColumnSearchProps('ANTIGUEDAD'),
    },
    {
        title: 'Puesto',
        dataIndex: 'PUESTO',
        key: 'PUESTO', 
        ...getColumnSearchProps('PUESTO'),
    },
    {
      title: 'Departamento',
      dataIndex: 'DEPARTAMENT',
      key: 'DEPARTAMENT', 
      ...getColumnSearchProps('DEPARTAMENT'),
    },
    {
        title: 'Rol',
        dataIndex: 'ROL',
        key: 'ROL', 
        ...getColumnSearchProps('ROL'),
    },
    {
        title: 'Empresa',
        dataIndex: 'EMPRESA',
        key: 'EMPRESA', 
        ...getColumnSearchProps('EMPRESA'),
    },
    {
        title: 'Región',
        dataIndex: 'REGION',
        key: 'REGION', 
        ...getColumnSearchProps('REGION'),
    },
    {
        title: 'Sucursal',
        dataIndex: 'SUCURSAL',
        key: 'SUCURSAL', 
        ...getColumnSearchProps('SUCURSAL'),
    },
    {
        title: 'Código gerente',
        dataIndex: 'CODGE',
        key: 'CODGE', 
        ...getColumnSearchProps('CODGE'),
    },
    {
        title: 'Nombre gerente',
        dataIndex: 'NOMGE',
        key: 'NOMGE', 
        ...getColumnSearchProps('NOMGE'),
    },
    {
        title: 'Email gerente',
        dataIndex: 'EMAILJEF',
        key: 'EMAILJEF', 
        ...getColumnSearchProps('EMAILJEF'),
    },   
    {
      title: 'Puesto gerente',
      dataIndex: 'PUESTOGE',
      key: 'PUESTOGE', 
      ...getColumnSearchProps('PUESTOGE'),
    },
];
const onChange = async (value) => {
    //console.log(dataLogin.CODIGOEMPRESA);
    //console.log(value[0]);
    setLoadingData(true)
    setidEnc(value)
    const postMutation = `
    mutation {
      empresaEmpleado(input: {ENC: "${String(value[0])}", EMP: "${String(dataLogin.CODIGOEMPRESA)}"}) {
        NOMBRE
        EMAILEMP
        CODEMP
        ANTIGUEDAD
        PUESTO
        ROL
        DEPARTAMENT
        SUCURSAL
        REGION
        EMPRESA
        ENCNO
        EMAILJEF
        CODGE
        ID
        NOMGE
        PUESTOGE
        }
      }
      `;
      const postInput = {
        "input": {
            "ENC": String(value[0]),
            "EMP": String(dataLogin.CODIGOEMPRESA)
        }
      }
      try{
        const response = await Api.getInstance().executeMutation(postMutation, postInput)
        //console.log(response)
        if(response.empresaEmpleado.length > 0){
            setLoadingData(false)
            setData(response.empresaEmpleado)
        }else{
            Swal.fire({
                title: 'Advertencia',
                text: 'No se encontraron datos',
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }
      }catch(ex){
        Swal.fire({
            title: 'Error',
            text: 'Ha ocurrido un error intente más tarde',
            icon: 'error',
            confirmButtonText: 'Ok'
        })
      }
        
       
  };
  const cancel = () => {
    setEditingKey('');
  };

useEffect(() => {
    cargarData();
    //console.log(data)
}, [])



  return (
    
    <div>
       
            <div className="headline-2 mb-3">Modificación de empleados</div>
            {/*breadcrumbTitle !== "Dashboard" &&
            <></>
            /*<Breadcrumb tag="nav" listTag="div">
                {renderBreadcrumbs()}
            </Breadcrumb>*/
            }
      
       
        
      {
        
        (loading )?
        (<Skeleton></Skeleton>):
        (
          <Card>
             <Row gutter={[16, 16]} className="mb-4">
                <Col xs={24} md={24} lg={12} xl={12}>
                    <Cascader 
                        options={options} 
                        onChange={(value) =>
                          onChange(value)
                        } 
                        placeholder="Seleccione encuesta" 
                        style={{
                            width: '100%',
                          }}
                    />
                </Col>
                
            </Row>
            
            <Table 
                  loading={loadingData} 
                  columns={columns} 
                  dataSource={data} 
                  pagination={{
                    onChange: cancel,
                    pageSize: 10,
                  }}
                  scroll={{
                      
                      x: 1000,
                  }}
                  bordered
          />
          </Card>
        )
      }
      {
        (openEditar === true) && (
            <Drawer
              title={'Modificar empleado'}
              placement="right"
              size={'large'}
              width={'60%'}
              onClose={CloseDrawer}
              open={openEditar}
            >
              {
                  openEditar && (
                    <EditarEmpleado 
                      idEmpleado={idEmpleado}
                      handleClose={CloseDrawer} 
                    ></EditarEmpleado>
                    
                  )
              }
            </Drawer>
        )
      }
      

    </div>
  )
}

export default ListaEmpleados;
