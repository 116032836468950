import { gql } from '@apollo/client'

const EMPALL = gql`
  query {
    empresasAll {
      empresa
      id
      idemp
    }
  }
`
export const PREGUNTAEMPRESABURN = gql`
  mutation empresaPreBurn($input: inputID) {
    empresaPreBurn(input: $input) {
      CORR
      DESCRIP
      ID
      IMAGEN
      PREGUNTA
      TIPO
    }
  }
`;

export const ANSWERBURN = gql`
  mutation encanswerBurn($input: inputEmpAdd) {
    encanswerBurn(input: $input) {
      mensaje
    }
  }
`;



export const VALIDENCBURN = gql`
  mutation encvalidBurn($input: inputValid) {
    encvalidBurn(input: $input) {
      ID
      CODEMP
      FEC
      EMP
      ENCNO
    }
  }
`;

export const PREGUNTA = gql`
  query {
    encuesta {
      CORR
      DESCRIP
      ID
      PREGUNTA
      TIPO
      IMAGEN
    }
  }
`

export const PREGUNTAEMPRESA = gql`
  mutation empresaPre($input: inputID) {
    empresaPre(input: $input) {
      CORR
      DESCRIP
      ID
      IMAGEN
      PREGUNTA
      TIPO
    }
  }
`

export const ADDEMPRESA = gql`
  mutation empresaAd($input: inputemp) {
    intoAcount(input: $input) {
      empresa
      estado
      id
      idemp
    }
  }
`

export const ANSWER = gql`
  mutation encanswer($input: inputEmpAdd) {
    encanswer(input: $input) {
      mensaje
    }
  }
`

export const VALIDENC = gql`
  mutation encvalid($input: inputValid) {
    encvalid(input: $input) {
      ID
      CODEMP
      FEC
      EMP
      ENCNO
    }
  }
`
