import { ApolloClient, 
    createHttpLink, 
    InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {API_SERVER} from './util/config'


//const cookies = new Cookies();

const httpLink = createHttpLink({
    uri: API_SERVER,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = cookies.get('accessToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        //authorization: token ? `${token}` : "",
      }
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });



export default client 
