import React, { Fragment, useEffect, useState } from 'react';
import cn from "classnames";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import s from "./Sidebar.module.scss";
import {MENU} from '../../conf/mutation';
import SofiaLogo from "../Icons/SofiaLogo.js";
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { tokenDatos } from '../../conf/token';
import LinksGroup from "./LinksGroup/LinksGroup.js";
import { changeActiveSidebarItem } from "../../actions/navigation.js";



const Sidebar = (props) => {


  const [mutatioMenu] = useMutation(MENU)
  const [menu, setMenu] = useState([])

  const {
    activeItem = '',
    ...restProps
  } = props;

  const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)
  const [dataLogin, setDataLogin] = useState(tokenDatos())

  const menuGet = async () => {

    let dataBus = {
      ID: dataLogin.ROL
    }
    const { data } = await mutatioMenu({
      variables: {
        input: dataBus,
      },
    })
    const {menuNav} = data
    //console.log(JSON.parse(menuNav.mensaje))
    setMenu(JSON.parse(menuNav.mensaje))
  }

  useEffect(() => {
    //console.log(localStorage.getItem('rol'))
    menuGet()
    if (props.sidebarOpened) {
      setBurgerSidebarOpen(true)
    } else {
      setTimeout(() => {
        setBurgerSidebarOpen(false)
      }, 0);
    }
  }, [props.sidebarOpened])

  return (
    <nav className={cn(s.root, {[s.sidebarOpen]: burgerSidebarOpen})} >
      <header className={s.logo}>
        <SofiaLogo/>
        <span className={s.title}></span>
      </header>
      <ul className={s.nav} id="35">
        <Fragment>
          {menu.map((item) => (
            <>
            <h5 className={s.navTitle}>{item.option}</h5>
            {
              item.children.map((op) =>(
              
                <LinksGroup
                  
                  onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
                  activeItem={props.activeItem}
                  header={op.header}
                  isHeader
                  iconName={<i className={op.iconName}/>}
                  link={op.link}
                  index={op.index}
                  badge=""
                />
              
              ))
              }
            </>
        ))}
      </Fragment>

          

        {/*<LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Typography"
          isHeader
          iconName={<i className={'eva eva-text-outline'}/>}
          link="/template/typography"
          index="typography"
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Tables"
          isHeader
          iconName={<i className={'eva eva-grid-outline'}/>}
          link="/template/tables"
          index="tables"
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="Notifications"
          isHeader
          iconName={<i className={'eva eva-bell-outline'}/>}
          link="/template/notifications"
          index="notifications"
        />
        <LinksGroup
          onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
          activeItem={props.activeItem}
          header="UI Elements"
          isHeader
          iconName={<i className={'eva eva-cube-outline'}/>}
          link="/template/uielements"
          index="uielements"
          childrenLinks={[
            {
              header: 'Charts', link: '/template/ui-elements/charts',
            },
            {
              header: 'Icons', link: '/template/ui-elements/icons',
            },
            {
              header: 'Google Maps', link: '/template/ui-elements/maps',
            },
          ]}
        />*/}
      </ul>
      <div className="bg-widget d-flex mt-auto ml-1">
        {/*<Button className="rounded-pill my-3 body-2 d-none d-md-block" type="submit" color="secondary-red">Unlock Full Version</Button>*/}
      </div>
    </nav>
  );
}

Sidebar.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  activeItem: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    activeItem: store.navigation.activeItem,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
