import React, { useEffect, useState } from 'react'
import { Element, animateScroll as scroll, scroller } from 'react-scroll'

import {  gql } from '@apollo/client'
import { PREGUNTA, ANSWER, VALIDENC, PREGUNTAEMPRESA } from '../../gpl/mutation'
import { useMutation } from '@apollo/client'
import {
  Button,
  Space,
  Radio,
  Card,
  Skeleton,
  message,
  Input,
  Slider,
  Alert,
  Row, Col 
} from 'antd'
import './Enc.css'
import { useParams } from 'react-router';
import icono from "./fondo/logoempo-1.png";
import { SendOutlined, DownCircleOutlined } from '@ant-design/icons';
import { useHistory, withRouter, Redirect, Link } from 'react-router-dom'

const { TextArea } = Input

const Enc = () => {
  let history = useHistory()
  let idEnc,
    empresa,
    idEmp = useParams()
  const [messageApi, contextHolder] = message.useMessage()
  //const { loading, error, data, refetch } = useQuery(PREGUNTA)
  const [ loading, setLoading ] = useState(true)
  const [ data, setData ] = useState([])
  const [respuesta, setRespuesta] = useState([])
  const [dataNew, setDataNew] = useState([])
  const [numeroImagen, setNumeroImagen] = useState(1)
  const [preguntaEmp] = useMutation(PREGUNTAEMPRESA)
  const [answerenc] = useMutation(ANSWER)
  const [valEncuestaC] = useMutation(VALIDENC)
  const [showAlert, setShowAlert] = useState(false)
  const [mensajeAlert, setMesajeAlert] = useState('Error')
  const [tipoAlerta, setTipoAlerta] = useState('warning')
  const [loadButton, setLoadButton] = useState(false)
  const [dataPreg, setDataPreg] = useState([])
  const [state, setState] = useState(
    {
      [1]: 0,
      [2]: 0,
      [3]: 0,
      [4]: 0,
      [5]: 0,
      [6]: 0,
      [7]: 0,
      [8]: 0,
      [9]: 0,
      [10]: 0,
      [11]: 0,
      [12]: 0,
      [13]: 0,
      [14]: 0,
      [15]: 0,
      [16]: 0,
      [17]: 0,
      [18]: 0,
      [19]: 0,
      [20]: 0,
      [21]: 0,
      [22]: 0,
      [23]: 0,
      [24]: 0,
      [25]: 0,
    }
  )
  const onChange = (event) => {
    localStorage.removeItem('respuestaEnc')
    const obj = {
      ENCNO: parseInt(idEmp.idEnc),
      IDPREG: parseInt(event.target.name),
      IDRESP: parseInt(event.target.value),
      RESP: event.target.id,
      EMP: idEmp.empresa,
      FECHA: new Date(),
      CODEMP: parseInt(idEmp.idEmp),
    }
    //console.log(event.target.name)
    if (parseInt(event.target.name) === 24 || parseInt(event.target.name) === 25) {
     //console.log('entro')
      setState({ ...state, [event.target.name]: event.target.value })
      //let num = parseInt(event.target.name) + 1
      //ira(num)
    }else{
      //console.log('entro a esle')
      setRespuesta((current) => [...current, obj])
      setState({ ...state, [event.target.name]: event.target.value })
      let num = parseInt(event.target.name) + 1
      ira(num)
    }
    setState({ ...state, [event.target.name]: event.target.value })
    localStorage.setItem('respuestaEnc', JSON.stringify(state))
    //console.log(state)
  }
  
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Tienes preguntas pendientes de responder',
    })
  }
  const onClickFinEncuesta = async (e) => {
    //console.log('respuesta encuesta: ', respuesta)
    setLoadButton(true)
    if (state[1] === 0) {
      ira(1)
      warning()
      setLoadButton(false)
      return
    }
    if (state[2] === 0) {
      ira(2)
      warning()
      setLoadButton(false)
      return
    }
    if (state[3] === 0) {
      ira(3)
      warning()
      setLoadButton(false)
      return
    }
    if (state[4] === 0) {
      ira(4)
      warning()
      setLoadButton(false)
      return
    }
    if (state[5] === 0) {
      ira(5)
      warning()
      setLoadButton(false)
      return
    }
    if (state[6] === 0) {
      ira(6)
      warning()
      setLoadButton(false)
      return
    }
    if (state[7] === 0) {
      ira(7)
      warning()
      setLoadButton(false)
      return
    }
    if (state[8] === 0) {
      ira(8)
      warning()
      setLoadButton(false)
      return
    }
    if (state[9] === 0) {
      ira(9)
      warning()
      setLoadButton(false)
      return
    }
    if (state[10] === 0) {
      ira(10)
      warning()
      setLoadButton(false)
      return
    }
    if (state[11] === 0) {
      ira(11)
      warning()
      setLoadButton(false)
      return
    }
    if (state[12] === 0) {
      ira(12)
      warning()
      setLoadButton(false)
      return
    }
    if (state[13] === 0) {
      ira(13)
      warning()
      setLoadButton(false)
      return
    }
    if (state[14] === 0) {
      ira(14)
      warning()
      setLoadButton(false)
      return
    }
    if (state[15] === 0) {
      ira(15)
      warning()
      setLoadButton(false)
      return
    }
    if (state[16] === 0) {
      ira(16)
      warning()
      setLoadButton(false)
      return
    }
    if (state[17] === 0) {
      ira(17)
      warning()
      setLoadButton(false)
      return
    }
    if (state[18] === 0) {
      ira(18)
      warning()
      setLoadButton(false)
      return
    }
    if (state[19] === 0) {
      ira(19)
      warning()
      setLoadButton(false)
      return
    }
    if (state[20] === 0) {
      ira(20)
      warning()
      setLoadButton(false)
      return
    }
    if (state[21] === 0) {
      ira(21)
      warning()
      setLoadButton(false)
      return
    }
    if (state[22] === 0) {
      ira(22)
      warning()
      setLoadButton(false)
      return
    }
    if (state[23] === 0) {
      ira(23)
      warning()
      setLoadButton(false)
      return
    }
    if (state[24] === "") {
      ira(24)
      warning()
      setLoadButton(false)
      return
    }
    if (state[25] === 0) {
      ira(25)
      warning()
      setLoadButton(false)
      return
    }
    
    try{
      
      const obj = {
        ENCNO: parseInt(idEmp.idEnc),
        IDPREG: parseInt(24),
        IDRESP: parseInt(0),
        RESP: state[24],
        EMP: idEmp.empresa,
        FECHA: new Date(),
        CODEMP: parseInt(idEmp.idEmp),
      }
      const obj2 = {
        ENCNO: parseInt(idEmp.idEnc),
        IDPREG: parseInt(25),
        IDRESP: parseInt(0),
        RESP: `${state[25]}`,
        EMP: idEmp.empresa,
        FECHA: new Date(),
        CODEMP: parseInt(idEmp.idEmp),
      }

      let objResp = []
      
      for (let item = 1; item < 24; item++) {
 
        //console.log(item)
        let textResp = ""
        if(state[item] === 1){
          textResp = "Totalmente en desacuerdo"
        }else if(state[item] === 2){
          textResp = "Desacuerdo"
        }else if(state[item] === 3){
          textResp = "De acuerdo"
        }else if(state[item] === 4){
          textResp = "Totalmente de acuerdo"
        }
        objResp.push({
          ENCNO: parseInt(idEmp.idEnc),
          IDPREG: parseInt(item),
          IDRESP: parseInt(state[item]),
          RESP: textResp,
          EMP: idEmp.empresa,
          FECHA: new Date(),
          CODEMP: parseInt(idEmp.idEmp),
        })
      }
          
 
      //console.log(objResp)
    
      let resp = {
        encuesta: [objResp],
        comentario: obj,
        recomendaEmpresa: obj2
      }
      
      //setRespuesta((current) => [...current, obj])
    // setRespuesta((current) => [...current, obj2])
      //console.log(resp)
      //return
      
      const { data } = await answerenc({
        variables: {
          input: { json: JSON.stringify(resp) },
        },
      })
      //console.log(data.encanswer)
      if (data.encanswer.mensaje > 1) {
        //console.log(data.encanswer)
        localStorage.removeItem('respuestaEnc')
        setLoadButton(false)
        history.push('/encfin')
        
      }else{
        localStorage.setItem('respuestaEnc', JSON.stringify(state))
        //console.log(data)
        setLoadButton(false)
        setShowAlert(true)
        setTipoAlerta('warning')
        setMesajeAlert('Ha ocurrido un error intenta nuevamente')
      }
  }catch(e){
    console.log(e)
    setLoadButton(false)
    setShowAlert(true)
    setTipoAlerta('error')
    setMesajeAlert('Ha ocurrido un error, intente nuevamente')
    localStorage.setItem('respuestaEnc', JSON.stringify(state))
  }
    //console.log(JSON.stringify(data.mensaje))
    //console.log(JSON.stringify(respuesta))
  }
  const ira = (id) => {
    scroller.scrollTo(`uno${id}`, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    })
  }
  const onCloseAlert = (e) => {
    setShowAlert(false)
  };
  const funPregunta = async () => {
    //console.log('entro fun pregunta')
    const datapar = {
      ID: parseInt(idEmp.empresa)
    }

    const { data } = await preguntaEmp({
      variables: {
        input: datapar,
      },
    })
    if (data != undefined) {
      //console.log('entro a if para mostrar')
      setData(data.empresaPre)
      setLoading(false)
    }
  }
  useEffect(() => {
    Ini()
    //console.log("ENTRO USE EFFECT")
    funPregunta()
    

    try{
  
      const encuestaData = localStorage.getItem('respuestaEnc')
      //console.log(JSON.parse(encuestaData))
      if(encuestaData.length > 0){
        setState(JSON.parse(encuestaData))
      }
      setDataPreg(data)
    }catch(e){

    }
    
    //console.log(idEmp)
    //console.log(data)
    validEncu()
  }, [])

  const validEncu = async () => {
    const datapar = {
      codemp: parseInt(idEmp.idEmp),
      encno: parseInt(idEmp.idEnc),
      emp: idEmp.empresa,
    }

    const { data } = await valEncuestaC({
      variables: {
        input: datapar,
      },
    })
    //console.log(data)
    const envalid = data.encvalid[0]
    if (envalid != undefined) {
      history.push('/encfin')
    }
  }
  const Ini = () => {
    scroll.scrollTo(10)
  }

  return loading ? (
    <Row>
        <Col>
          <div className="div1 ">
            <div className="principal ">
              <div className="tranparente ">
                <Element name="test1" >
                  <Row >
                    <Col xs={2} md={2} lg={2} xl={4}>
                    </Col>
                    <Col className='height-100' xs={20} md={20} lg={20} xl={16}>
                      <div className='mt-5'></div>
                      <div className='mt-5'></div>
                      <Skeleton></Skeleton> 
                    </Col>
                    <Col  xs={2} md={2} lg={2} xl={4}>
                    </Col>
                  </Row>
                  
                </Element>
              </div>
            </div>
          </div>
      </Col>
    </Row>                      
  ) : (
    <div className="Container  hideScroll">
      {contextHolder}
      <Row>
        <Col>
          <div className="div1 ">
            <div className="principal ">
              <div className="tranparente ">
                <Element name="test1" >
                  <Row>
                    <Col xs={2} md={2} lg={2} xl={4}>
                    </Col>
                    <Col className='height-100' xs={20} md={20} lg={20} xl={16}>
                      <Space className='height-100' align="center">
                        <div>
                          <img className='imagen-logo' src={icono} alt="Empowerment" />
                          <h5 className='mt-5'>
                            Por favor tomate el tiempo de leer cada pregunta y de
                            contestar lo mas honestamente posible. Ajusta tu respuesta
                            lo mas posible a las opciones presentadas. Recuerda que la
                            encuesta es totalmente anónima.
                          </h5>
                          <Row className='mt-5'> 
                            <Col xs={5} md={5} lg={8} xl={8}>
                            </Col>
                            <Col xs={14} md={14} lg={8} xl={8}>
                              <Button
                              type="primary"
                              onClick={() => {
                                ira(1)
                              }}
                              size="large"
                              danger
                              block
                            >
                              ¡ Empezar !
                            </Button>
                            </Col>
                            <Col xs={5} md={5} lg={8} xl={8}>
                            </Col>
                          </Row>
                          
                        </div>
                      </Space>
                    </Col>
                    <Col  xs={2} md={2} lg={2} xl={4}>
                    </Col>
                  </Row>
                </Element>
              </div>
            </div>
          </div>
          {data.map(({ ID, PREGUNTA, IMAGEN }) => (
            <div key={ID}>
            <div className="div1">
              <Element name={'uno' + ID} className="hijo">
                <div className="div1 ">
                  <div className={IMAGEN}>
                  <div className="tranparente">
                    <Row>
                      <Col xs={3} md={3} lg={6} xl={8}>
                      </Col>
                      <Col className='height-100' xs={18} md={18} lg={12} xl={8}>
                        <Space className='height-100' align="center">
                          <div>
                            <Card
                              bordered={false}
                              style={{
                                width: '100%',
                              }}
                            >
                              <h3>{PREGUNTA}</h3>
                              {parseInt(ID) === 24 ? (
                                <>
                                  <TextArea
                                    name={ID}
                                    id={ID}
                                    placeholder=""
                                    style={{ width: '100%' }}
                                    onChange={onChange}
                                    autoSize={{
                                      minRows: 4,
                                      maxRows: 6,
                                    }}
                                  />
                                  <Row justify="end">
                                    <Col xs={12} md={12} lg={6} xl={8}>
                                      
                                    </Col>
                                    <Col xs={12} md={12} lg={6} xl={8}>
                                      <Button
                                        className='mt-2'
                                        type="primary"
                                        size='large'
                                        block
                                        onClick={() => {ira(25)}}
                                        icon={<DownCircleOutlined />}
                                      >
                                        Siguiente
                                      </Button>
                                    </Col>
                                  </Row>
                                </>
                              ) : parseInt(ID) === 25 ? (
                                <div className="mt-5">
                                  <Slider
                                    name ={ID}
                                    value={state[25]}
                                    onChange={(value) =>
                                      setState({ ...state, [25]: value })
                                    }
                                    min={1}
                                    max={10}
                                    marks={{
                                      1: '1',
                                      2: '2',
                                      3: '3',
                                      4: '4',
                                      5: '5',
                                      6: '6',
                                      7: '7',
                                      8: '8',
                                      9: '9',
                                      10: '10',
                                    }}
                                  />
                                </div>
                              ) : (
                                <Radio.Group
                                  name={ID}
                                  onChange={onChange}
                                  value={state[ID]}
                                >
                                  <Space direction="vertical">
                                    <Radio value={4} id="Totalmente de acuerdo">
                                      Totalmente de acuerdo
                                    </Radio>
                                    <Radio value={3} id="De acuerdo">
                                      De acuerdo
                                    </Radio>
                                    <Radio value={2} id="Desacuerdo">
                                      Desacuerdo
                                    </Radio>
                                    <Radio
                                      value={1}
                                      id="Totalmente en desacuerdo"
                                    >
                                      Totalmente en desacuerdo
                                    </Radio>
                                  </Space>
                                </Radio.Group>
                              )}
                            </Card>
                              {(parseInt(ID) === 25)?(
                                <>
                                  {
                                    (showAlert)?(
                                      <div>
                                          <Alert 
                                            className='mt-3'
                                            message={mensajeAlert} 
                                            type={tipoAlerta} 
                                            showIcon 
                                            closable 
                                            onClose={onCloseAlert}
                                          />
                                      </div>
                                    ):(
                                      <></>
                                    )
                                  }
                                  <Row justify="center">
                                    <Col  xs={1} md={1} lg={8} xl={8}>
                                    </Col>
                                    <Col xs={23} md={23} lg={8} xl={8}>
                                      <div className='mt-3'>
                                        <Button
                                          type="primary"
                                          size='large'
                                          block
                                          loading={loadButton}
                                          onClick={onClickFinEncuesta}
                                          icon={<SendOutlined />}
                                        >
                                          Enviar
                                        </Button>
                                      </div>
                                    </Col>
                                    <Col xs={5} md={5} lg={8} xl={8}>
                                    </Col>
                                  </Row>
                                </>
                              ):(
                                <></>
                              )}
                          </div>
                        </Space>
                      </Col>
                      <Col  xs={3} md={3} lg={6} xl={8}>
                      </Col>
                    </Row>
                    </div>
                    </div>
                  </div>
                </Element>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    
    </div>
  )
}

export default Enc
