import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import jwt from 'jwt-decode';
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from 'reactstrap';
import {
  Alert
} from 'antd';
import { tokenDatos } from '../../conf/token';
import Widget from '../../components/Widget/Widget'
import Footer from '../../components/Footer/Footer'
import { loginUser } from '../../actions/auth'
import hasToken from '../../services/authService'
import loginImage from '../../assets/registerImage.svg'
//import loginImage from "../../assets/freelancer-763730_1280.fw.png";
import { gql, useQuery, useMutation } from '@apollo/client'
import SofiaLogo from '../../components/Icons/SofiaLogo.js'
import { LOGIN } from '../../conf/mutation'
import { SetMealRounded } from '@mui/icons-material'

const RecuperarContraseña = (props) => {
  const [loginenc] = useMutation(LOGIN)
  const [showAlert, setShowAlert] = useState(false)
  const [dataLogin, setDataLogin] = useState(tokenDatos())
  const [mensajeAlert, setMesajeAlert] = useState('Error')
  const [tipoAlerta, setTipoAlerta] = useState('warning')
  const [dataUsu, setDataUsu] = useState([])
  const [state, setState] = useState({
    email: '',
    password: '',
  })

  const doLogin = async (value) => {
    //console.log(state.email)
    value.preventDefault()
    try{
      const dataRecuperar = {
        email: state.email,
      }
  
      //console.log(dataLogin)
  
      const response = await axios.post('http://137.184.217.236:3025/api/recover', dataRecuperar)
      const { data } = response
      console.log(data)
      if(data.result.Estado === '0'){
        setMesajeAlert(data.result.mensaje)
        setTipoAlerta('success')
        setShowAlert(true)
      }else{
        setMesajeAlert(data.result.mensaje)
        setTipoAlerta('warning')
        setShowAlert(true)
      }
      
    }catch(e){
      setShowAlert(true)
      setTipoAlerta('error')
      setMesajeAlert('Ha ocurrido un error, intente nuevamente')
      console.log(e)
    }
    
    
  }

  const changeCreds = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const { from } = props.location.state || { from: { pathname: '/template' } }
  
  if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
    const dat = jwt(localStorage.getItem('token'))
    console.log('login rol:', dat.ROL)
    if(dat.ROL === 'ADMINEMP'){
      return <Redirect to={ { pathname: '/template/dashboardAdmin' }}/>
    }
    if(dat.ROL === 'ADMINCOUCH'){
      return <Redirect to={ { pathname: `/template/dashboard` }}/>
      
    }
    if(dat.ROL === 'ADMIN'){
      return <Redirect to={ { pathname: '/template/empresas' }}/>
      
    }else{
      return <Redirect to={from} />
    }
    
  }

  return (
    <div className="auth-page">
      <Container className="col-12">
        <Row className="d-flex align-items-center">
          <Col xs={12} lg={6} className="left-column">
            <Widget className="widget-auth widget-p-lg">
                <div className="logo-block">
                <SofiaLogo />
              </div>
              <div className=" auth-info my-2">
                <p className="texto-titulo mb-0">
                    <b>¿Olvidaste tu contraseña?</b> 
                    
                </p>
                <p >
                     Si tienes acceso a tu correo electronico, te llegará el correo con la información para que puedas recuperar tu contraseña.
                    
                </p>
              </div>
              
              {/*<div className="auth-info my-2">
                   <p>This is a real app with Node.js backend - use <b>"admin@flatlogic.com / password"</b> to login!</p>
                 </div>*/}
              <form onSubmit={(event) => doLogin(event)}>
                <FormGroup className="my-3">
                  <FormText>Email</FormText>
                  <Input
                    id="email"
                    className="input-transparent pl-3"
                    value={state.email}
                    onChange={(event) => changeCreds(event)}
                    type="email"
                    required
                    name="email"
                    placeholder="Email"
                  />
                </FormGroup>
              
                 {
                    (showAlert)?(
                      <Row>
                        <Col>
                          <Alert message={mensajeAlert} type={tipoAlerta} showIcon />
                        </Col>
                      </Row>
                    ):(
                      <></>
                    )
                    
                 }
                <div className="bg-widget d-flex justify-content-center">
                  <Button
                    className="rounded-pill my-3"
                    type="submit"
                    color="primary"
                  >
                    Recuperar contraseña
                  </Button>
                </div>
                
                {/*
                <p className="dividing-line my-3">&#8195;Or&#8195;</p>
                <div className="d-flex align-items-center my-3">
                  <p className="social-label mb-0">Login with</p>
                  <div className="socials">
                    <a href="https://flatlogic.com/"><GoogleIcon /></a>
                    <a href="https://flatlogic.com/"><TwitterIcon /></a>
                    <a href="https://flatlogic.com/"><FacebookIcon /></a>
                    <a href="https://flatlogic.com/"><GithubIcon /></a>
                    <a href="https://flatlogic.com/"><LinkedinIcon /></a>
                  </div>
                </div>
                <Link to="/register">Don’t have an account? Sign Up here</Link>
                */}
              </form>
            </Widget>
          </Col>
          <Col xs={0} lg={6} className="right-column">
            <img src={loginImage} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}



function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  }
}

export default withRouter(connect(mapStateToProps)(RecuperarContraseña))
