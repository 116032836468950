import React, { useEffect, useState, ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Drawer,
  Card,
  Space,
  Table,
  Tag,
  Popover,
  Button,
  Skeleton,
  Popconfirm,
  Form,
  DatePicker,
  Badge,
  notification,
  Tooltip,
  Modal,
  Spin,
  Typography,
  Result,
} from "antd";
import dayjs from "dayjs";
import readXlsxFile from "read-excel-file";
import { gql, useQuery, useMutation } from "@apollo/client";
import "./style.css";
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FolderAddOutlined,
  CloudUploadOutlined,
  FileExcelFilled,
  CloseSquareOutlined,
  DotChartOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { QUERY_STRING_GET_EMPRESAS } from "../../../gpl/mantenimientoEmpresas";
import CreacionEmpresa from "./CreacionEmpresa";
import { EMPRESASENC, EMPADENCUESTA, UPLOADEMP } from "../../../conf/mutation";
import Api from '../../../Api';
import Swal from 'sweetalert2';
const GET_EMPRESAS = gql`
  ${QUERY_STRING_GET_EMPRESAS}
`;

const ListaEmpresas = () => {
  const [openCrearEmpresa, setOpenCrearEmpresa] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [encuestas] = useMutation(EMPRESASENC);
  const [addencuesta] = useMutation(EMPADENCUESTA);
  const [uploademp] = useMutation(UPLOADEMP);
  const [EncExist, setEncExist] = useState([]);
  const [empresa, setempresa] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState();
  const { RangePicker } = DatePicker;
  const { Paragraph, Text } = Typography;
  const [EmpresaAd, setEmpresaAd] = useState("");
  const [LoadingC, setLoadingC] = useState(false);
  const { loading, data, refetch } = useQuery(gql`
    query {
      empresasAll {
        ID
        STATUS
        LOGO
        EMPRESA
        CREATEAT
        UPDATEAT
      }
    }
  `);

  const CloseDrawer = () => {
    setOpenCrearEmpresa(false);
    refetch();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const openNotificationWithIcon = (type, mensaje) => {
    api[type]({
      message: "Aviso",
      description: mensaje,
    });
  };

  const tipoTag = (record) => {
    //console.log(record)
    if (record.estado === "1") {
      return (
        <Tag key={record.id} icon={<CloseCircleOutlined />} color="error">
          Inactivo
        </Tag>
      );
    }
    if (record.estado === "0") {
      return (
        <Tag key={record.id} icon={<CheckCircleOutlined />} color="success">
          Activo
        </Tag>
      );
    }
  };

  const getEnc = async ({ EMPRESA }) => {
    try {
      const { data } = await encuestas({
        variables: {
          input: { ID: EMPRESA },
        },
      });

      setEncExist(data.empresaallEnc);
      setempresa(EMPRESA);
      setOpen(true);
    } catch (error) {}
  };
  const onClose = () => {
    setOpen(false);
  };
  const AbrirCrearEmpresa = (newOpen) => () => {
    setOpenCrearEmpresa(newOpen);
  };
  const EliminarEmpresa = async (record) => {

    try {
      const postUser_query = `
      mutation {
        empresaDel(input: {ID: "${record.ID}"}) {
          ID
          CREATEAT
          EMPRESA
          LOGO
          STATUS
          UPDATEAT
        }
      }
      
        `;

      const postUser_values = {
          "input": {
              "ID": record.ID,
              
          }
      }


      const response = await Api.getInstance().executeMutation(postUser_query, postUser_values)
      const { empresaDel } = response
     //console.log(empresaDel)
      if (parseInt(empresaDel[0].ID) > 0) {
          Swal.fire({
              title: 'Información',
              text: 'Empresa eliminada exitosamente',
              icon: 'success',
              confirmButtonColor: '#ff8f00',
              confirmButtonText: 'Ok'

          })
          refetch();
      } else {
          Swal.fire({
              title: 'Advertencia',
              text: 'Ha ocurrido un error no se elimino la empresa',
              icon: 'warning',
              confirmButtonText: 'Ok'
          })
      }

  } catch (e) {
      console.log(e)

  }
  };

  const onFinish = async (values) => {
    const dataenc = {
      EMP: empresa,
      ENCNO: 3,
      FECHA: values.fechas[0],
      FECIN: values.fechas[0],
      FECFIN: values.fechas[1],
      STATUS: "1",
    };

    // try {
    const { data } = await addencuesta({
      variables: {
        input: dataenc,
      },
    });

    if (
      data.empresaAdEnc[0].ENCNO === 0 ||
      data.empresaAdEnc[0].ENCNO === "0"
    ) {
      openNotificationWithIcon("warning", data.empresaAdEnc[0].EMP);
    } else {
      openNotificationWithIcon("success", "Encuesta creado con exito");
      setEncExist(data.empresaAdEnc);
    }
    //openNotificationWithIcon("success");
    //setOpen(false);
    // } catch (error) {}
  };
  const OpenUpload = (record) => {
    setEmpresaAd(record);
    showModal();
  };

  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };
  const columnsEnc = [
    {
      title: "NO",
      dataIndex: "ENCNO",
      key: "ENCNO",
      width: 500,
    },
    {
      title: "ESTADO",
      dataIndex: "STATUS",
      key: "STATUS",
      render: (_, { STATUS }) => (
        <>
          {STATUS == 0 ? (
            <Badge
              className="site-badge-count-109"
              count={"FINALIZADO"}
              style={{ backgroundColor: "#ff0000" }}
            />
          ) : (
            <Badge
              className="site-badge-count-109"
              count={"EN PROCESO"}
              style={{ backgroundColor: "#52c41a" }}
            />
          )}
        </>
      ),
    },
    {
      title: "FECNA INICIO",
      dataIndex: "FECFIN",
      key: "FECFIN",
      width: 500,
    },
    {
      title: "FECHA FINAL",
      dataIndex: "FECIN",
      key: "FECIN",
      width: 500,
    },
    {
      title: "",
      dataIndex: "ID",
      key: "ID",
      render: (text, record) => (
        <>
          <Space.Compact block>
            <Tooltip title="Subir archivo excel" color={"#2ECC71"}>
              <Button
                size="large"
                type="dashed"
                icon={<CloudUploadOutlined />}
                onClick={() => {
                  OpenUpload(record);
                }}
              />
            </Tooltip>
            <Tooltip title="Finalizar encuesta" color={"#ba000d"}>
              <Button
                type="dashed"
                size="large"
                danger
                onClick={() => console.log("Hola")}
                icon={<CloseSquareOutlined />}
              />
            </Tooltip>
          </Space.Compact>
        </>
      ),
    },
  ];
  const columns = [
    {
      title: "Empresa",
      dataIndex: "EMPRESA",
      key: "EMPRESA",
      width: 500,
    },
    {
      title: "",
      dataIndex: "ID",
      key: "ID",
      fixed: "left",
      width: 20,
      render: (text, record) => (
        <Space>
          <Popover
            content={`Crea una nueva encuesta para ${record.EMPRESA}`}
            title="Encuesta"
          >
            <Button
              type="primary"
              shape="circle"
              color="blue"
              icon={<QuestionCircleOutlined />}
              onClick={() => getEnc(record)}
            />
          </Popover>
        </Space>
      ),
    },
    {
      title: "",
      dataIndex: "ID",
      key: "ID",
      fixed: "left",
      width: 20,
      render: (text, record) => (
        <Space>
          <Popconfirm
            title="Eliminar"
            description={`Esta seguro de eliminar la empresa  ${record.EMPRESA}?`}
            okText="SI"
            cancelText="NO"
            onConfirm={() => EliminarEmpresa(record)}
          >
            {/* <Button icon={<CloseOutlined />} onClick={() => EliminarEmpresa(record)}>Eliminar</Button>  */}
            <Button
              type="primary"
              shape="circle"
              danger
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    //console.log(data);
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = async () => {
    if (!file) {
      return;
    }
    readXlsxFile(file).then(async (rows) => {
      setLoadingC(true);
      var datae = [];
      for (var i in rows) {
        var CODEMP = rows[i][0];
        var NOMBRE = rows[i][1];
        var PUESTO = rows[i][2];
        var CODGE = rows[i][3];
        var NOMGE = rows[i][4];
        var PUESTOGE = rows[i][5];
        var EMPRESA = rows[i][6];
        var EMAILEMP = rows[i][7];
        var EMAILJEF = rows[i][8];
        var ROL = rows[i][9];
        var REGION = rows[i][10];
        var DEPARTAMENT = rows[i][11];
        var ENCNO = rows[i][12];
        var SUCURSAL = rows[i][13];
        var ANTIGUEDAD = rows[i][14];

        datae.push({
          CODEMP: CODEMP,
          NOMBRE: NOMBRE,
          PUESTO: PUESTO,
          CODGE: CODGE,
          NOMGE: NOMGE,
          PUESTOGE: PUESTOGE,
          EMPRESA: EMPRESA,
          EMAILEMP: EMAILEMP,
          EMAILJEF: EMAILJEF,
          ROL: ROL,
          REGION: REGION,
          DEPARTAMENT: DEPARTAMENT,
          ENCNO: ENCNO,
          SUCURSAL: SUCURSAL,
          ANTIGUEDAD: ANTIGUEDAD,
        });
      }
      const jsString = JSON.stringify(datae);

      const { data } = await uploademp({
        variables: {
          input: { json: jsString },
        },
      });
      setLoadingC(false);
      //console.log(JSON.stringify(data));
    });
  };

  return (
    <div>
      <div className="headline-2 mb-3">Empresas</div>
      {loading ? (
        <Skeleton></Skeleton>
      ) : (
        <Card>
          <div className="mb-4">
            <Button
              size="large"
              color="#6B676E"
              onClick={AbrirCrearEmpresa(true)}
              icon={<FolderAddOutlined />}
            >
              Crear empresa
            </Button>
          </div>

          <Table
            loading={loading}
            columns={columns}
            dataSource={data.empresasAll}
            pagination={true}
            size="small"
            bordered
          />

          <Modal
            title="Subir empleados"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            width={"50%"}
          >
            {LoadingC ? (
              <div>
                <Result
                  icon={
                    <FileExcelFilled
                      style={{ fontSize: "100px", color: "#44c767" }}
                    />
                  }
                  title={`${EmpresaAd.EMP}`}
                  subTitle={`Encuesta No: ${EmpresaAd.ENCNO}`}
                  extra={[
                    <Skeleton.Node active={"active"}>
                      <LoadingOutlined
                        style={{ fontSize: 40, color: "#1565c0" }}
                      />
                    </Skeleton.Node>,
                  ]}
                >
                  <Skeleton.Input
                    active={"active"}
                    size={"large"}
                    block="block"
                  />
                  <h7>
                    Espere estamos procesando el documento
                    <spam>
                      <Text type="danger"> No cierre la ventana</Text>
                    </spam>
                  </h7>
                </Result>
              </div>
            ) : (
              <Result
                icon={
                  <FileExcelFilled
                    style={{ fontSize: "100px", color: "#44c767" }}
                  />
                }
                title={`${EmpresaAd.EMP}`}
                subTitle={`Encuesta No: ${EmpresaAd.ENCNO}`}
                extra={[
                  <input
                    id="uploadFile"
                    type="file"
                    multiple={false}
                    accept=".xlsx,.xls"
                    onChange={handleFileChange}
                  />,
                ]}
              >
                <div className="desc">
                  <Button
                    className="myButton"
                    value={"large"}
                    type="dashed"
                    block
                    onClick={() => {
                      handleUploadClick();
                    }}
                  >
                    PROCESAR ARCHIVO
                  </Button>
                  <Button type="link">Descargar plantilla</Button>
                  <Paragraph>
                    <Text
                      strong
                      style={{
                        fontSize: 16,
                      }}
                    >
                      error:
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    <CloseCircleOutlined className="error-icon" /> posibles
                    errores <a>corregir &gt;</a>
                  </Paragraph>
                  <Paragraph>
                    <CloseCircleOutlined className="error-icon" /> posibles not
                    errores <a>corregir &gt;</a>
                  </Paragraph>
                </div>
              </Result>
            )}
          </Modal>
          <Drawer
            title="Nueva encuesta"
            width={"50%"}
            placement={"right"}
            onClose={onClose}
            open={open}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="primary" onClick={onClose}>
                  OK
                </Button>
              </Space>
            }
          >
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Fecha inicio/Final"
                name="fechas"
                rules={[
                  { required: true, message: "Debe seleccionar una fecha!" },
                ]}
              >
                <RangePicker size={"large"} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Crear encuesta
                </Button>
              </Form.Item>
            </Form>

            <Table columns={columnsEnc} dataSource={EncExist} />
          </Drawer>
          {contextHolder}
        </Card>
      )}
      {openCrearEmpresa === true && (
        <Drawer
          title={"Creación de empresa"}
          placement="right"
          size={"large"}
          width={"50%"}
          onClose={CloseDrawer}
          open={openCrearEmpresa}
        >
          {openCrearEmpresa && (
            <CreacionEmpresa handleClose={CloseDrawer}></CreacionEmpresa>
          )}
        </Drawer>
      )}
    </div>
  );
};

export default ListaEmpresas;
