import React, {useState} from "react";
import { 
    Card,
    Form,
    Input,
    Button,
    Col,
    Row,
    Select,
    Alert,
    Layout,
    Skeleton,
    Table,
    Drawer,
    Modal,
    Upload,
    DatePicker
  } from 'antd';



const { Option } = Select;
const { TextArea } = Input;

const AgregarTarea = ({handleClose}) => {

    const [form] = Form.useForm();
    
    const [msgAlert, setMsgAlert] = useState('Alerta de prueba');
    const [datEmp, setDataEmp ] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [disableInput, setdisableInput] = useState(false);

    const onFinish = async (values) => {
       
        
    };
    const validateMessages = {
        required: '${label} es un campo requerido',
    };
    const formItemLayout = {
      
    };

  return (
    <div className="ml-4 pl-4 mr-4 pr-4">
        <Form 
            form={form}
            name="nest-messages" 
            onFinish={onFinish} 
            validateMessages={validateMessages}
            layout="vertical"
        >
             <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                    
                    <Form.Item
                        {...formItemLayout}
                        name={['título']}
                        label="Titulo"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        name={["empleados"]}
                        label="Empleados"
                        rules={[
                            {
                            required: true,
                            },
                        ]}
                        >
                        <Select
                            style={{ width: '100%' }}
                        >
                            {datEmp != null? datEmp.map((data, index) => <Option value={data.ROL} key={`${data.ROL}${index}`}>{data.ROL}</Option>):(<Option></Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                            {...formItemLayout}
                            name={['link']}
                            label="Link para reunión"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input disabled={disableInput} />
                        
                    </Form.Item>
                    <Form.Item 
                        name={["fecha"]}
                        label="Fecha" 
                        rules={[
                            {
                            type: 'array',
                            required: true,
                            message: 'Por favor selecciona fecha y hora para la reunión',
                            },
                        ]}
                    >
                        <DatePicker 
                            showTime 
                            format="DD-MM-YYYY HH:mm" 
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                            {...formItemLayout}
                            name={['descripcion']}
                            label="Descripción"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                            <Input disabled={disableInput} />
                        
                    </Form.Item>
                    
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                    
                        {
                            (showAlert === true)?(
                                <Alert

                                    message="Alerta"
                                    description={msgAlert}
                                    type="warning"
                                    showIcon
                                    closable
                                />
                            ):(
                                <div></div>
                            )
                        }
                    
                </Col>
                
                <Col  xs={24} md={24} lg={12} xl={12}>
                    <Form.Item
                        >
                        <Button type="primary" block htmlType='submit' loading={loading} >Guardar</Button>
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12} xl={12} >
                    <Form.Item
                        >
                        <Button type="default"  loading={loading} onClick={(event) => handleClose()} block  >Cancelar</Button>
                    </Form.Item>
                </Col>
            </Row>
            
            
        </Form>
    </div>
  );
};

export default AgregarTarea;
