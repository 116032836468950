import { gql } from "@apollo/client";

export const EMPRESASENC = gql`
  mutation empresaAd($input: inputID) {
    empresaallEnc(input: $input) {
      EMP
      ENCNO
      FECFIN
      FECHA
      FECIN
      ID
      STATUS
    }
  }
`;

export const EMPADENCUESTA = gql`
  mutation empresaAdEnc($input: inputEncuestas) {
    empresaAdEnc(input: $input) {
      ID
      EMP
      ENCNO
      FECHA
      STATUS
      FECIN
      FECFIN
    }
  }
`;

export const UPLOADEMP = gql`
  mutation empresaEmpAd($input: inputEmpAdd) {
    empresaEmpAd(input: $input) {
      mensaje
    }
  }
`;

export const UPDATEEMP = gql`
  mutation empresaUpdateemp($input: inputEmpAdd) {
    empresaUpdateemp(input: $input) {
      mensaje
    }
  }
`;

export const LOGIN = gql`
  mutation loginadmin($input: inputLogin) {
    loginadmin(input: $input) {
      estado
      mensaje
    }
  }
`;

export const MENU = gql`
  mutation menuNav($input: inputIDstring) {
    menuNav(input: $input) {
      mensaje
    }
  }
`;

export const PERFALTANTES = gql`
  mutation emprefal($input: inputIDstring) {
    emprefal(input: $input) {
      CODEMP
      EMAILEMP
      NOMGE
      NOMBRE
      PUESTO
    }
  }
`;

export const EMPRESAALLENC = gql`
  mutation {
    empresaallEnc(input: { ID: "SOLUCIONES TI" }) {
      EMP
      ENCNO
      FECFIN
      FECHA
      FECIN
      ID
      STATUS
    }
  }
  mutation emprefal($input: inputIDstring) {
    emprefal(input: $input) {
      CODEMP
      EMAILEMP
      NOMGE
      NOMBRE
      PUESTO
    }
  }
`;

export const PERFALTANTESNOM = gql`
  mutation emprefal($input: inputIDstring) {
    emprefal(input: $input) {
      NOMBRE
    }
  }
`;

export const PERFALTANTESPUE = gql`
  mutation emprefal($input: inputIDstring) {
    emprefal(input: $input) {
      PUESTO
    }
  }
`;

export const ENCCHATTOT = gql`
  mutation encChatTot($input: inputTotEng) {
    encChatTot(input: $input) {
      mensaje
    }
  }
`;
export const ENCCHATTOTEmp = gql`
  mutation encChatTotG($input: inputID) {
    encChatTotG(input: $input) {
      mensaje
    }
  }
`;
export const ENCCHATPLANAC = gql`
  mutation planAccChat($input: inputPlacc) {
    planAccChat(input: $input) {
      mensaje
    }
  }
`;

export const encGraficaLinea = gql`
  mutation encChartLine($input: inputParEnc) {
    encChartLine(input: $input) {
      mensaje
    }
  }
`;

export const encRadarCouch = gql`
  mutation punteo($input: inputCouch) {
    punteo(input: $input) {
      mensaje
    }
  }
`;

export const encPunteoMP4 = gql`
  mutation punteoMP4($input: inputCouch) {
    punteoMP4(input: $input) {
      mensaje
    }
  }
`;

export const encComentario = gql`
  mutation encComent($input: inputCouch) {
    encComent(input: $input) {
      mensaje
    }
  }
`;

export const encNiveles = gql`
  mutation nivelcom($input: inputCouch) {
    nivelcom(input: $input) {
      mensaje
    }
  }
`;

export const encNivelesGeneral = gql`
  mutation nivelEmp($input: inputCouch) {
    nivelEmp(input: $input) {
      mensaje
    }
  }
`;

export const encEquipo = gql`
  mutation equipo($input: inputEquipo) {
    equipo(input: $input) {
      mensaje
    }
  }
`;

export const encPreguntasRes = gql`
  mutation getpunteoequipo($input: inputpunteo) {
    getpunteoequipo(input: $input) {
      mensaje
    }
  }
`;

export const encPunteoRespuesta = gql`
  mutation getdetpunteo($input: inputpunteo) {
    getdetpunteo(input: $input) {
      mensaje
    }
  }
`;

export const AgregarPlanAccion = gql`
  mutation createPlaccion($input: inputplaccion) {
    createPlaccion(input: $input) {
      mensaje
    }
  }
`;

export const PlanAccionActivo = gql`
  mutation planAccion($input: inputpunteo) {
    planAccion(input: $input) {
      mensaje
    }
  }
`;
export const PlanAccionFinalizado = gql`
  mutation planAccion($input: inputpunteo) {
    planAccion(input: $input) {
      mensaje
    }
  }
`;

export const EncAnterior = gql`
  mutation encanterior($input: inputpunteo) {
    encanterior(input: $input) {
      mensaje
    }
  }
`;

export const FinalizarPlanAccion = gql`
  mutation finplanAcc($input: inputfinPlaAccion) {
    finplanAcc(input: $input) {
      mensaje
    }
  }
`;

export const ListEncuestaAnt = gql`
  mutation encanterior($input: inputpunteo) {
    encanterior(input: $input) {
      mensaje
    }
  }
`;

export const punteoENPS = gql`
  mutation punteoENPS($input: inputEquipo) {
    punteoENPS(input: $input) {
      mensaje
    }
  }
`;

export const finalizarTutorial = gql`
  mutation tutorial($input: inputTutorial) {
    tutorial(input: $input) {
      mensaje
    }
  }
`;

export const calendarioMuta = gql`
  mutation calendario($input: inputIDstring) {
    calendario(input: $input) {
      mensaje
    }
  }
`;

export const calendarioDiaDet = gql`
  mutation calendariodet($input: inputIDdet) {
    calendariodet(input: $input) {
      mensaje
    }
  }
`;

export const ReporteAvances = gql`
  mutation repavance($input: inputID) {
    repavance(input: $input) {
      mensaje
    }
  }
`;

export const ReporteEmpresa = gql`
  mutation repgeneral($input: inputEmpleados) {
    repgeneral(input: $input) {
      mensaje
    }
  }
`;

export const ReportePlanAcc = gql`
  mutation empplan($input: inputPlacc) {
    empplan(input: $input) {
      mensaje
    }
  }
`;

export const Region = gql`
  mutation region($input: inputEmpleados) {
    region(input: $input) {
      mensaje
    }
  }
`;
export const Rol = gql`
  mutation rol($input: inputEmpleados) {
    rol(input: $input) {
      mensaje
    }
  }
`;
export const Sucursal = gql`
  mutation suc($input: inputEmpleados) {
    suc(input: $input) {
      mensaje
    }
  }
`;

export const Puesto = gql`
  mutation puesto($input: inputEmpleados) {
    puesto(input: $input) {
      mensaje
    }
  }
`;
export const Departamento = gql`
  mutation dep($input: inputEmpleados) {
    dep(input: $input) {
      mensaje
    }
  }
`;

export const BurnChats = gql`
  mutation burnChart($input: inputEmpleados) {
    burnChart(input: $input) {
      mensaje
    }
  }
`;

export const BurnGeneral = gql`
  mutation burnOutGeneral($input: inputEmpleados) {
    burnOutGeneral(input: $input) {
      mensaje
    }
  }
`;

export const BurnFilter = gql`
  mutation burnOutGenFilter($input: inputFilter) {
    burnOutGenFilter(input: $input) {
      mensaje
    }
  }
`;

export const BURNLOGIN = gql`
  mutation burnOutLogin($input: inputEmail) {
    burnOutLogin(input: $input) {
      mensaje
    }
  }
`;
