import React, { useState, useEffect } from "react";
import NavBarindex from "./componet/NavBarindex";
import "./estilo.css";
import { Idioma } from "../../conf/Idioma";

import { Tabs } from "antd";
import { useHistory, useLocation } from "react-router-dom";

const Funcionalidades = () => {

  const [esMovil, setEsMovil] = useState(false);
  let history = useHistory();

  const [datosIdioma] = useState(Idioma());

  const onChange = (key) => {
    console.log(key);
  };
  const contacto = () => {
    history.push("/contacto");
  };
  const items = [
    {
      key: "1",
      label: datosIdioma.funacionalidad.evaluacionTitulo,
      children: <Evaluacion />,
    },
    {
      key: "2",
      label: datosIdioma.funacionalidad.reporteTitulo,
      children: <Reporte />,
    },
    {
      key: "3",
      label: datosIdioma.funacionalidad.planTitulo,
      children: <PlaAccion />,
    },
    {
      key: "4",
      label: datosIdioma.funacionalidad.segTitulo,
      children: <Seguimiento />,
    },
  ];
  useEffect(() => {
    const verificarTamanioVentana = () => {
      // Obtén el ancho de la ventana
      const anchoVentana = window.innerWidth;

      // Define un umbral (puedes ajustarlo según tus necesidades)
      const umbralMovil = 768;

      // Comprueba si el ancho de la ventana es menor que el umbral
      setEsMovil(anchoVentana < umbralMovil);
    };

    // Registra el evento de cambio de tamaño de la ventana
    window.addEventListener("resize", verificarTamanioVentana);

    // Verifica el tamaño de la ventana cuando el componente se monta
    verificarTamanioVentana();

    // Limpia el evento al desmontar el componente
    return () => {
      window.removeEventListener("resize", verificarTamanioVentana);
    };
  }, []); // El segundo parámetro [] asegura que useEffect se ejecute solo una vez al montar el componente

  return (
    <>
      <NavBarindex
        pageindex="nav-link"
        pagesomos="nav-link"
        pagefuncional="active"
        pagecontacto="nav-link"
      />

      <div className="row bg-white">
        <div className="col-md-12 d-flex align-items-center justify-content-center marequipo ">
          <h1 className="text-center text-wrap animate__animated animate__fadeInLeft animate__delay-1s">
            <p style={{ color: "#000000" }}>
              {datosIdioma.funacionalidad.funcion}
            </p>
          </h1>
        </div>
        <div>
          {esMovil ? (
            <Tabs
              defaultActiveKey="0"
              size="large"
              items={items}
              onChange={onChange}
            />
          ) : (
            <Tabs
              defaultActiveKey="1"
              centered
              size="large"
              items={items}
              onChange={onChange}
            />
          )}
        </div>

       
        <div className="col-md-12 d-flex align-items-center justify-content-center marequipo animate__animated animate__fadeInLeft animate__delay-1s">
          <h1 className="text-center text-wrap">
            <p style={{ color: "#000000", fontSize: "50px" }}>
              {datosIdioma.equipo.info}
            </p>
          </h1>
        </div>
        <div className="col-md-12 text-center text-wrap estlismabu animate__animated animate__fadeInRight animate__delay-2s">
          <button className="myButton " onClick={() => contacto()}>
            {datosIdioma.Inicio.agenda}
          </button>
        </div>

        <footer className="footer mt-auto py-3 bg-light animate__animated animate__fadeInRight animate__delay-2s">
          <div className="container text-left">
            <span className="text-muted">
              {datosIdioma.Inicio.contacto} <br />​ 502 - 4770 - 9215 <br />​
              info@empowerment.com
            </span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Funcionalidades;

const T = () => {
  <h4>Evaluaciòn</h4>;
};
const Evaluacion = () => {
  const [datosIdioma] = useState(Idioma());
  return (
    <div className="col-md-12 animate__animated animate__fadeInLeft animate__delay-1s">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-4">
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <p className="funci">
                {datosIdioma.funacionalidad.evaluDesc}{" "}
                <strong>{datosIdioma.funacionalidad.evaluDescS}</strong>
                {datosIdioma.funacionalidad.evaluDescOtro}
              </p>
            </li>
            <li>
              <p className="funci">
                {datosIdioma.funacionalidad.evaluDesc2}
                <strong>{datosIdioma.funacionalidad.evaluDescS2} </strong>{" "}
                {datosIdioma.funacionalidad.evaluDescS2Otro}{" "}
                <strong>{datosIdioma.funacionalidad.evaluDescOtro2} </strong>
                {datosIdioma.funacionalidad.evaluDescOtro2S}
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-6 container mt-3">
          <img
            className="img  img-fluid"
            src="https://static.wixstatic.com/media/b91e83_544dd3a8e34f42409c41c574b4ed51ee~mv2.png/v1/fill/w_519,h_144,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b91e83_544dd3a8e34f42409c41c574b4ed51ee~mv2.png"
            alt="Italian Trulli"
          />
        </div>
      </div>
    </div>
  );
};

const Reporte = () => {
  const [datosIdioma] = useState(Idioma());
  return (
    <div className="col-md-12 animate__animated animate__fadeInLeft animate__delay-1s">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-4">
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <p className="funci">
                {datosIdioma.funacionalidad.reporteDesc}
                <strong>{datosIdioma.funacionalidad.reporteDescS}</strong>
                {datosIdioma.funacionalidad.reporteDescOtro}
              </p>
            </li>
            <li>
              <p className="funci">
                {datosIdioma.funacionalidad.reporteDesc2}
                <strong>{datosIdioma.funacionalidad.reporteDescS2}</strong>{" "}
                {datosIdioma.funacionalidad.reporteDescOtro2}
                <strong>{datosIdioma.funacionalidad.reporteDescOtro2S}</strong>
                {datosIdioma.funacionalidad.reporteDescUltimo}
              </p>
            </li>
            <li>
              <p className="funci">
                <strong> {datosIdioma.funacionalidad.reporteDesc3}</strong>
                {datosIdioma.funacionalidad.reporteDescOtro3}
              </p>
            </li>
            <li>
              <p className="funci">
                <strong>{datosIdioma.funacionalidad.reporteDesc4}</strong>
                {datosIdioma.funacionalidad.reporteDescOtro4}
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-6  mt-3">
          <img
            className="img-fluid"
            src="https://static.wixstatic.com/media/b91e83_1227577b649843d1917bab770abd7866~mv2.png/v1/fill/w_601,h_417,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b91e83_1227577b649843d1917bab770abd7866~mv2.png"
            alt="Italian Trulli"
          />
        </div>
      </div>
    </div>
  );
};

const PlaAccion = () => {
  const [datosIdioma] = useState(Idioma());
  return (
    <div className="col-md-12 animate__animated animate__fadeInLeft animate__delay-1s">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-4">
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <p className="funci">
                {datosIdioma.funacionalidad.planDesc}
                <strong>{datosIdioma.funacionalidad.planDescS}</strong>
                {datosIdioma.funacionalidad.planDescOtro}
              </p>
            </li>
            <li>
              <p className="funci">
                {datosIdioma.funacionalidad.planDesc2}{" "}
                <strong>{datosIdioma.funacionalidad.planDesc2S}</strong>
                {datosIdioma.funacionalidad.planDesc2Otro}
              </p>
            </li>
            <li>
              <p className="funci">
                <strong>{datosIdioma.funacionalidad.planDesc3}</strong>
                {datosIdioma.funacionalidad.planDesc3S}
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-6  mt-3">
          <img
            className="img-fluid"
            src="https://static.wixstatic.com/media/b91e83_db60c7cfa0ac4ad1b235a721be6ce21e~mv2.png/v1/fill/w_639,h_198,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b91e83_db60c7cfa0ac4ad1b235a721be6ce21e~mv2.png"
            alt="Italian Trulli"
          />
        </div>
      </div>
    </div>
  );
};

const Seguimiento = () => {
  const [datosIdioma] = useState(Idioma());

  return (
    <div className="col-md-12 animate__animated animate__fadeInLeft animate__delay-1s">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-4">
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <p className="funci">
                {datosIdioma.funacionalidad.segDesc}
                <strong>{datosIdioma.funacionalidad.segDescS}</strong>{" "}
                {datosIdioma.funacionalidad.segDescOtro}
              </p>
            </li>
            <li>
              <p className="funci">
                {datosIdioma.funacionalidad.segnDesc2}{" "}
                <strong>{datosIdioma.funacionalidad.segDesc2S}</strong>
                {datosIdioma.funacionalidad.segDescU}
                <strong>{datosIdioma.funacionalidad.segDesc3}</strong>
                {datosIdioma.funacionalidad.segDesc4}
              </p>
            </li>
          </ul>
        </div>
        <div className="col-md-6  mt-3">
          <img
            className="img-fluid"
            src="https://static.wixstatic.com/media/b91e83_f913909d24ea406080a4fac299996c3e~mv2.png/v1/fill/w_652,h_411,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/b91e83_f913909d24ea406080a4fac299996c3e~mv2.png"
            alt="Italian Trulli"
          />
        </div>
      </div>
    </div>
  );
};
