import React from "react";
import { Badge } from "antd";
const Fatiga = () => {
  return (
    <Badge.Ribbon text="Fatiga Social" color="green">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Colaboradores con Fatiga Social */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Colaboradores con Fatiga Social
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Este perfil corresponde a personas que se sienten desconectadas de
            su trabajo y de las personas con las que interactúan, lo que puede
            llevar a un trato impersonal y distante con compañeros y clientes.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Estas personas no encuentran sentido o propósito en sus tareas
            diarias, donde su percepción de ineficacia aumenta.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - En el contexto de esta prueba, un colaborador con sentimiento de
            fatiga social cuenta con:
            <ul
              style={{
                listStyleType: "circle",
                paddingLeft: "40px",
                marginTop: "5px",
              }}
            >
              <li>Nivel alto de despersonalización</li>
            </ul>
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li>
            - Realizar una evaluación detallada de las funciones y
            responsabilidades del personal para asegurar que estén alineadas con
            sus habilidades y aspiraciones.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Fatiga;
