import React from "react";
import { Badge } from "antd";
const Continuarmonit = () => {
  return (
    <Badge.Ribbon text="Continuar Monitoreando" color="yellow">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Continuar Monitoreando */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Continuar Monitoreando
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Se encuentran en un momento perfecto para llevarlos al compromiso,
            pero una mala gestión podría comenzar a afectarlos en los otros
            factores y llevarlos a otro rubro.
          </li>
          <li>
            - En el contexto de esta prueba, una persona con clasificación de
            “continuar monitoreando” no tienen punteos altos ni bajos en ninguno
            de los factores.
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Es importante realizar acciones que los lleven a incrementar los
            niveles de realización personal, sin afectar los niveles de
            cansancio emocional y despersonalización.
          </li>
          <li>
            - Se pueden desarrollar sentimientos positivos sobre el trabajo que
            se tiene por medio de reconocimientos, feedback, independencia en
            sus responsabilidades, y por medio de crecimiento laboral.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Continuarmonit;
