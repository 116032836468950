import React from 'react';
import MantenimientoRoutes from './pages/Mantenimientos/MantenimientoRoutes';
import CalendarioRoutes from './pages/calendar/CalendarRoutes';
import EmpAdminRoutes from './pages/empadmin/EmpAdminRoutes';
import DashboardRoutes from './pages/dashboard/DashboardRoutes';


const routes = [
    ...MantenimientoRoutes,
    ...CalendarioRoutes,
    ...EmpAdminRoutes,
    ...DashboardRoutes
]

export default routes