import React from "react";
import { Badge } from "antd";
const Apaticos = () => {
  return (
    <Badge.Ribbon text="Apáticos" color="green">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Colaboradores Apáticos */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Colaboradores Apáticos
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Se caracterizan por ser colaboradores más retraídos, ofrecen un
            esfuerzo menor que el de sus compañeros y no muestran cansancio
            emocional. Sin embargo, sus actitudes tienden a ser negativas.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - En el contexto de esta prueba, un colaborador apático cuenta con:
            <ul
              style={{
                listStyleType: "circle",
                paddingLeft: "40px",
                marginTop: "5px",
              }}
            >
              <li>Nivel alto de despersonalización</li>
              <li>Nivel bajo de realización personal</li>
            </ul>
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Entrega de responsabilidades distintas, el desarrollo de un
            propósito institucional del puesto, re enmarcando el puesto para
            demostrar la importancia de sus labores.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Es importante revisar si han existido cambios en las
            responsabilidades o carga laboral que las personas con esta
            clasificación han vivido, pues un cambio en las condiciones
            laborales tiende a tener un impacto fuerte en este perfil.
          </li>
          <li>
            - Otorgar procesos para manejo de estrés y emociones, asegurando que
            esto nos ayude a tener un mejor contacto con los clientes internos y
            externos.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Apaticos;
