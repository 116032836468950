import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import s from './ErrorPage.module.scss'

import errorImage from '../../assets/iniciarsesion.png'

import FooterIcon from '../../components/Icons/FooterIcon.js'

const Encrealizada = () => {
  return (
    <div className={s.pageContainer}>
      <div className={s.errorContainer}>
        <h1 className={s.errorCode}></h1>
        <p className={s.errorInfo}>
          Encuesta contestada exitosamente.
        </p>
        <p className={s.errorHelp}>
         
        </p>
        <Link to="/template/dashboard/:codJe/:codEnc">
          <Button
            className={`${s.errorBtn} rounded-pill`}
            type="submit"
            color="secondary-red"
          >
            Regresar
          </Button>
        </Link>
      </div>
      <div className={s.imageContainer}>
        <img
          className={s.errorImage}
          src={errorImage}
          alt="Error page"
          width="80"
        />
      </div>
      <div className={s.footer}>
        <span className={s.footerLabel}>2022 &copy; Soluciones TI</span>
        <FooterIcon />
      </div>
    </div>
  )
}

export default Encrealizada
