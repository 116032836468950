import React, { useState, useEffect }  from "react";
import{
  Skeleton,
  } from 'antd';
import { Radar } from '@ant-design/plots';
import { encPunteoRespuesta } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';
import { ContentPasteSearchOutlined } from "@mui/icons-material";

const ResultadosRadar = ({empresa, codigoEmp, idEncuesta, tipoResultado}) => {

  const [encRadarDetalle] = useMutation(encPunteoRespuesta)
  const [load, setLoad] = useState(true)
  const [dataRadar, setDataReadar] = useState([])

  const config = {
    data: dataRadar,
    xField: 'name',
    yField: 'Resultado',
    appendPadding: [5, 5, 5, 5],
    meta: {
      Resultado: {
        alias: '',
        min: 0,
        max: 10,
        nice: true,
        formatter: (v) => Number(v).toFixed(0),
      },
    },
    xAxis: {
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    
    point: {
      size: 1,
    },
    area: {},
    color: '#FF8F00'
  
  };
  const getDataChart = async () => {
    //let codEmp = "4004"
    console.log(tipoResultado)
   try{
    const {data} = await encRadarDetalle(
      {
        variables:{
          input: { CODJE: String(codigoEmp), EMP: String(empresa), TIP: tipoResultado, ENC: parseInt(idEncuesta)}
        }
      }
    )
    const {mensaje} = data.getdetpunteo
    //console.log(mensaje)
    const dataJson = JSON.parse(mensaje)

    //console.log(dataJson.respuesta.length)
    if(dataJson.respuesta.length === 0){

    }else{
      let op = [];
      dataJson.respuesta.map((item) => (
          op.push(
          {
              name: item.desc,
              Resultado: item.valor
          }
          )
          
      ))
    
      setDataReadar(op)
      console.log(op)
      setLoad(false)
    }
    
   }catch(ex){
      console.log(ex)
   }
    

  }
  useEffect(() => {
    getDataChart()
  }, [])
  return (
    <>
    {
      (load)?
      ( 
        <Skeleton className="mt-5"></Skeleton>
      ):
      (
        <div >
          <div >
            {/*<div className="my-3 body-3 muted">Different types of notifications for lots of use cases. Custom classes</div>*/}
            <Radar {...config} />
          </div>
        </div>
      )
    }
    </>
    
  );
};

export default ResultadosRadar;
