import React, { useState, useEffect } from "react";
import { Form, Input, Button, notification } from "antd";
import NavBarindex from "./componet/NavBarindex";
import "./estilo.css";
import { Idioma } from "../../conf/Idioma";

const Contactenos = () => {
  const [datosIdioma] = useState(Idioma());
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement) => {
    api.info({
      message: `Aviso`,
      description: "Mensaje enviado.",

      placement,
    });
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const nombre = e.target.elements.nombre.value;
    const apellido = e.target.elements.apellido.value;
    const telefono = e.target.elements.telefono.value;
    const email = e.target.elements.email.value;
    const empresa = e.target.elements.empresa.value;
    const mensaje = e.target.elements.mensaje.value;

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      nombre: nombre,
      apellido: apellido,
      telefono: telefono,
      email: email,
      empresa: empresa,
      mensaje: mensaje,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("http://137.184.217.236:3025/api/sendemail", requestOptions)
      .then((response) => response.text())
      .then((result) => openNotification("top"))
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBarindex
        pageindex="nav-link"
        pagesomos="nav-link"
        pagefuncional="nav-link"
        pagecontacto="active"
      />
      {contextHolder}
      <div className="container-fluid colblanco">
        <div className="col-md-12 mb-5">
          <div className="row ">
            <div className="col-md-12 d-flex align-items-center justify-content-center marequipo animate__animated animate__fadeInLeft animate__delay-1s">
              <h1 className="text-center">
                <p style={{ color: "#000000" }}>
                  {datosIdioma.contacto.contacto}
                </p>
              </h1>
            </div>
            <div className="col-md-12">
              <div className="row">
                <section className="ftco-section animate__animated animate__fadeInRight animate__delay-2s">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-md-12">
                        <div className="wrapper">
                          <div className="row no-gutters">
                            <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                              <div className="contact-wrap w-100 p-md-5 p-4">
                                <h3 className="mb-4">
                                  {datosIdioma.contacto.formulario}
                                </h3>
                                <div
                                  id="form-message-warning"
                                  className="mb-4"
                                ></div>
                                <div id="form-message-success" className="mb-4">
                                  Your message was sent, thank you!
                                </div>
                                <form
                                  onSubmit={handleSubmit}
                                  id="contactForm"
                                  name="contactForm"
                                  className="contactForm"
                                >
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label className="label" htmlFor="name">
                                          {datosIdioma.contacto.nombre}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="name"
                                          id="name"
                                          placeholder={
                                            datosIdioma.contacto.nombre
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label
                                          className="label"
                                          htmlFor="email"
                                        >
                                          {datosIdioma.contacto.apellidos}
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          name="email"
                                          id="email"
                                          placeholder={
                                            datosIdioma.contacto.apellidos
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          className="label"
                                          htmlFor="subject"
                                        >
                                          {datosIdioma.contacto.telefono}
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="subject"
                                          id="subject"
                                          placeholder={
                                            datosIdioma.contacto.telefono
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          className="label"
                                          htmlFor="subject"
                                        >
                                          Email
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          name="subject"
                                          id="email"
                                          placeholder="Email"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          className="label"
                                          htmlFor="message"
                                        >
                                          {datosIdioma.contacto.empresa}
                                        </label>
                                        <textarea
                                          name="message"
                                          className="form-control"
                                          id="message"
                                          cols="30"
                                          rows="4"
                                          placeholder={
                                            datosIdioma.contacto.empresa
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          className="label"
                                          htmlFor="message"
                                        >
                                          {datosIdioma.contacto.mensaje}
                                        </label>
                                        <textarea
                                          name="message"
                                          className="form-control"
                                          id="message"
                                          cols="30"
                                          rows="4"
                                          placeholder={
                                            datosIdioma.contacto.mensaje
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <input
                                          type="submit"
                                          value={datosIdioma.contacto.boton}
                                          className="btn btn-primary"
                                        />
                                        <div className="submitting"></div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                              <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                                <h3>{datosIdioma.contacto.contacto}</h3>
                                <p className="mb-4">
                                  {datosIdioma.contacto.desc}
                                </p>

                                <div className="dbox w-100 d-flex align-items-center">
                                  <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="fa fa-phone"></span>
                                  </div>
                                  <div className="text pl-3">
                                    <p>
                                      <span>
                                        {datosIdioma.contacto.telefono}:
                                      </span>{" "}
                                      <a href="tel://47709215">
                                        (502) 4770-9215
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div className="dbox w-100 d-flex align-items-center">
                                  <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="fa fa-paper-plane"></span>
                                  </div>
                                  <div className="text pl-3">
                                    <p>
                                      <span>Email:</span>{" "}
                                      <a href="mailto:info@empowerment.com">
                                        info@empowerment.com
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div className="dbox w-100 d-flex align-items-center">
                                  <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="fa fa-globe"></span>
                                  </div>
                                  <div className="text pl-3">
                                    <p>
                                      <span>Website</span>{" "}
                                      <a href="#">empowerment4.com</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer mt-auto py-3 bg-light">
          <div className="container text-left">
            <span className="text-muted">
              {datosIdioma.Inicio.contacto} <br />​ 502 - 4770 - 9215 <br />​
              info@empowerment.com
            </span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Contactenos;
