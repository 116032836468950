import React, {Fragment, useState} from "react";
import { 
    Form,
    Input,
    Button,
    Col,
    Row,
    Select,
    Alert,    
    DatePicker
  } from 'antd';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { AgregarPlanAccion } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';
import { tokenDatos } from '../../../conf/token';

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const AgregarPlan = ({handleClose}) => {
    const [PlanAccion] = useMutation(AgregarPlanAccion)
    const [dataPunto, setDataPunto] = useState([])
    const [dataLogin] = useState(tokenDatos())
    const [form] = Form.useForm();
    
    const [msgAlert, setMsgAlert] = useState('Alerta de prueba');
    
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [disableInput, setdisableInput] = useState(false);

    const onFinish = async (values) => {
        try{
            console.log(dayjs(values.fecha[0]).format('DD/MM/YYYY'))

            console.log(dayjs(values.fecha[1]).format('DD/MM/YYYY'))
        //return
            const {data} = await PlanAccion(
                {
                  variables:{
                    input: { 
                                TIPO: values.tipo, 
                                PUNTO: values.punto, 
                                FECINICIO: dayjs(values.fecha[0]).format('YYYY-MM-DD'), 
                                FECFIN: dayjs(values.fecha[1]).format('YYYY-MM-DD'), 
                                OBJETIVO: values.objetivo, 
                                DESCRIP: values.descripcion,
                                CODEMP: dataLogin.CODEMP, 
                                EMP: String(dataLogin.CODIGOEMPRESA),
                                ENCNO: dataLogin.ENCUESTANO
                            }
    
                  }
                }
              )
              //console.log(data)
              //return
              const { mensaje } = data.createPlaccion
              let dataResult = JSON.parse(mensaje)
              //console.log(dataResult)
              if(dataResult.respuesta === 'Plan accion creado con exito'){
                handleClose()
                Swal.fire({
                    title: 'Información',
                    text: 'Plan de acción creado exitosamente',
                    icon: 'success',
                    confirmButtonColor: '#ff8f00',
                    confirmButtonText: 'Ok'
                })
              }else{
                setMsgAlert('Ha ocurrido un error, no se creo el plan de acción')
                setShowAlert(true)
              }
        }catch(e){
            setMsgAlert('Ha ocurrido un error, intente nuevamente')
            setShowAlert(true)
        }
        
        
    };
    const validateMessages = {
        required: '${label} es un campo requerido',
    };
    const formItemLayout = {
      
    };

    const onChangeTipo = (value) => {
        switch (value) {
          case 'Autorrealización':
           setDataPunto([
                {
                    key: 0,
                    value: 'Aprendizaje y crecimiento',
                    name: 'Aprendizaje y crecimiento'
                },
                {
                    key: 1,
                    value: 'Desarrollo personal',
                    name: 'Desarrollo personal'
                },
                {
                    key: 3,
                    value: 'Independencia en el trabajo',
                    name: 'Independencia en el trabajo'
                },
                {
                    key: 4,
                    value: 'Misión importante',
                    name: 'Misión importante'
                },
                {
                    key: 5,
                    value: 'Reconocimiento',
                    name: 'Reconocimiento'
                },
                {
                    key: 6,
                    value: 'Valoración de opiniones',
                    name: 'Valoración de opiniones'
                },
           ])
            return;
          case 'Pertenencia':
            setDataPunto([
                {
                    key: 1,
                    value: 'Amistades',
                    name: 'Amistades'
                },
                {
                    key: 2,
                    value: 'Interés por mi persona',
                    name: 'Interés por mi persona'
                },
                {
                    key: 3,
                    value: 'Optimista sobre el futuro',
                    name: 'Optimista sobre el futuro'
                },
                {
                    key: 4,
                    value: 'Revisión de progreso',
                    name: 'Revisión de progreso'
                },
                {
                    key: 5,
                    value: 'Trabajo importante',
                    name: 'Trabajo importante'
                },
           ])
            return;
          case 'Dirección y objetivo':
            setDataPunto([
                {
                    key: 1,
                    value: 'Cambios anunciados',
                    name: 'Cambios anunciados'
                },
                {
                    key: 2,
                    value: 'Evaluaciones justas',
                    name: 'Evaluaciones justas'
                },
                {
                    key: 3,
                    value: 'Herramientas',
                    name: 'Herramientas'
                },
                {
                    key: 4,
                    value: 'Objetivos alcanzables',
                    name: 'Objetivos alcanzables'
                },
                {
                    key: 5,
                    value: 'Organización en mi área',
                    name: 'Organización en mi área'
                },
                {
                    key: 5,
                    value: 'Sé lo que se espera de mi',
                    name: 'Sé lo que se espera de mi'
                },
           ])
                return;
          case 'Incentivo y motivación':
            setDataPunto([
                {
                    key: 1,
                    value: 'Compromiso de mis compañeros',
                    name: 'Compromiso de mis compañeros'
                },
                {
                    key: 2,
                    value: 'Cumplimiento del equipo',
                    name: 'Cumplimiento del equipo'
                },
                {
                    key: 3,
                    value: 'Desarrollo profesional',
                    name: 'Desarrollo profesional'
                },
                {
                    key: 4,
                    value: 'Entrenamiento necesario',
                    name: 'Entrenamiento necesario'
                },
                {
                    key: 5,
                    value: 'Ingresos justos',
                    name: 'Ingresos justos'
                },
                {
                    key: 6,
                    value: 'Resolución de conflictos',
                    name: 'Resolución de conflictos'
                },
           ])
            break;
          default:
        }
        //console.log(dataPunto)
      };
      const onChangePunto = (value) => {
        
      };
  return (
    <div className="ml-4 pl-4 mr-4 pr-4">
        <Form 
            form={form}
            name="nest-messages" 
            onFinish={onFinish} 
            validateMessages={validateMessages}
            layout="vertical"
        >
             <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                    
            
                    <Form.Item 
                        {...formItemLayout}
                        name={['tipo']}
                        label="Tipo"   
                        rules={[
                            {
                                required: true,
                                
                            },
                            
                            ]}
                            
                    >
                        <Select
                            placeholder="Seleccione tipo para plan de acción"
                            onChange={onChangeTipo}
                            showSearch
                            disabled={disableInput}
                            name="tipo"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value="Dirección y objetivo">Dirección y objetivo</Option>
                            <Option value="Incentivo y motivación">Incentivo y motivación</Option>
                            <Option value="Pertenencia">Pertenencia</Option>
                            <Option value="Autorrealización">Autorrealización</Option>
                        </Select>
                    </Form.Item>
             
                    <Form.Item
                        {...formItemLayout}
                        name={['punto']}
                        label="Punto específico"
                        rules={[
                        {
                            required: true,
                        },
                        ]}
                    >
                         <Select
                            placeholder="Seleccione punto específico"
                            onChange={onChangePunto}
                            showSearch
                            disabled={disableInput}
                            name="tipo"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }

                        >
                            {dataPunto !== null? dataPunto.map((data) => <Option value={data.value} key={data.key}>{data.name}</Option>):(<Option></Option>)}
                            
                        </Select>
                    </Form.Item>
                    <Form.Item
                            {...formItemLayout}
                            name={['fecha']}
                            label="Fecha"
                            rules={[
                            {
                                required: true,
                            },
                            ]}
                        >
                             <RangePicker 
                                style={{ width: '100%' }}
                                format='DD/MM/YYYY'
                            />
                        
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        name={['objetivo']}
                        label="Objetivo de plan de acción"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <TextArea 
                            showCount 
                            maxLength={150}  
                            
                        />
                    </Form.Item>
                    
                
                    <Form.Item
                        {...formItemLayout}
                        name={['descripcion']}
                        label="Describe la metodología"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                       <TextArea 
                            showCount 
                            rows={6}
                            maxLength={300}  
                            
                        />
                    </Form.Item>
                    
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                    
                        {
                            (showAlert === true)?(
                                <Alert

                                    message="Alerta"
                                    description={msgAlert}
                                    type="warning"
                                    showIcon
                                    closable
                                />
                            ):(
                                <div></div>
                            )
                        }
                    
                </Col>
                
                <Col  xs={24} md={24} lg={12} xl={12}>
                    <Form.Item
                        >
                        <Button type="primary" block htmlType='submit' loading={loading} >Guardar</Button>
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12} xl={12} >
                    <Form.Item
                        >
                        <Button type="default"  loading={loading} onClick={(event) => handleClose()} block  >Cancelar</Button>
                    </Form.Item>
                </Col>
            </Row>
            
            
        </Form>
    </div>
  );
};

export default AgregarPlan;
