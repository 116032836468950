import React, { useState, useEffect } from "react";
import{
  Skeleton,
  } from 'antd';
import ApexCharts from 'react-apexcharts';
import { encGraficaLinea } from '../../../conf/mutation';
import { gql, useQuery, useMutation } from '@apollo/client';

export default function ApexActivityChart({empleado, encuesta}) {
  const [encGrafica] = useMutation(encGraficaLinea)
  const [load, setLoad] = useState(true)
  const [data, setdata] = useState([]);
  const [label, setLabel] = useState([])

  const chartSettings = {
    colors: ['#FFCA41', '#43BC13'],
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      width: [0, 1],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      style: {
        fontSize: '10px',
        fontWeight: 500,
      },
      background: {
        borderWidth: 0,
      },
    },
    labels: label,
    legend: {
      position: 'top',
      floating: true,
    },
    xaxis: {
      type: 'category',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: '#6B859E',
        },
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
      },
    },
    grid: {
      show: false,
    },
  }

  const getTotalEnc = async () => {
    //console.log(encuesta)
    //console.log(empleado)
    const {data} = await encGrafica(
      {
        variables:{
          input: { EMP: empleado, ENC: encuesta}
        }
      }
    )
    //console.log('resp: ',data.encChartLine.mensaje)
    let dataJson = JSON.parse(data.encChartLine.mensaje)
    //console.log(dataJson)
    let obj =  [ {
      name: dataJson[0].Contestada.name,
      type: 'column',
      data: dataJson[0].Contestada.data,
    },
    {
      name: dataJson[1].Pendientes.name,
      type: 'line',
      data: dataJson[1].Pendientes.data,
    },]
    
    setdata(obj)
    setLabel(dataJson[2].dataLabel)
    setLoad(false)
    //console.log(dataTotalResp)
  }
  useEffect(() => {
    getTotalEnc()
  }, [])

  return (
    <>
      {
        (load)?
        ( 
          <Skeleton className="mt-5"></Skeleton>
        ):
        (
          <ApexCharts
            options={chartSettings}
            series={data}
            type="area"
            height={275}
          />
        )
      }
    </>
  )
}
