export const QUERY_STRING_GET_EMPRESAS = `
{ 
    empresasAll {
        created_at
        empresa
        estado
        id
        idemp
        updated_at
    }
}`;

