import React from "react";
import { Badge } from "antd";

const Burnouttext = () => {
  return (
    <Badge.Ribbon text="Colaboradores con Burnout" color="green">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Colaboradores con Burnout */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Colaboradores con Burnout
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Este perfil se describe como personas con un estado emocional y
            físico caracterizado por agotamiento e irritabilidad.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Está relacionado directamente a eventos y situaciones que
            enfrentan las personas de manera constante en el trabajo.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - En el contexto de esta prueba, un colaborador con sentimientos de
            burnout cuenta con:
            <ul
              style={{
                listStyleType: "circle",
                paddingLeft: "40px",
                marginTop: "5px",
              }}
            >
              <li>Nivel alto de cansancio emocional</li>
              <li>Nivel alto de despersonalización</li>
              <li>Nivel alto de realización personal</li>
            </ul>
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li>
            - Abordarlo a través de un enfoque integral, considerando reducción
            de carga laboral, promover el bienestar emocional y físico,
            reconocimiento de logros y participación activa en la toma de
            decisiones.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Burnouttext;
