import React from "react";
import { Badge } from "antd";
const Comprometido = () => {
  return (
    <Badge.Ribbon text="Comprometidos" color="green">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Colaboradores Comprometidos */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Colaboradores Comprometidos
        </h3>
        <ul
          style={{
            listStyleType: "none",
            paddingLeft: "0",
            marginTop: "0",
          }}
        >
          <li style={{ marginBottom: "10px" }}>
            - Se caracteriza por un sentimiento de identidad con la
            organización.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - Tiene una mejor actitud, mejor rendimiento y tiende a permanecer
            más tiempo dentro de la organización.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - En el contexto de esta prueba, un colaborador comprometido cuenta
            con:
            <ul
              style={{
                listStyleType: "circle",
                paddingLeft: "40px",
                marginTop: "5px",
              }}
            >
              <li>Nivel bajo de cansancio emocional</li>
              <li>Nivel bajo de despersonalización</li>
              <li>Nivel alto de realización personal</li>
            </ul>
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul
          style={{
            listStyleType: "none",
            paddingLeft: "0",
            marginTop: "0",
          }}
        >
          <li>
            - Es importante continuar con el desarrollo de estas personas y
            aprovechar las condiciones que se encuentran presentemente para ir
            determinando qué hace que estas personas se encuentren comprometidas
            y qué se puede replicar en los otros equipos.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Comprometido;
