import React from "react";
import { Badge } from "antd";
const Ineficaz = () => {
  return (
    <Badge.Ribbon text="Ineficacia en el trabajo" color="green">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Colaboradores con sentimientos de Ineficacia en el trabajo */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Colaboradores con sentimientos de Ineficacia en el trabajo
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Este perfil corresponde a personas que el trabajo que están
            realizando no los llena emocionalmente, no cumplen con sus
            expectativas de vida, o bien no han conseguido cumplir con sus
            propias expectativas.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - En el contexto de esta prueba, un colaborador con sentimientos de
            ineficacia en el trabajo cuenta con:
            <ul
              style={{
                listStyleType: "circle",
                paddingLeft: "40px",
                marginTop: "5px",
              }}
            >
              <li>Nivel bajo de realización personal</li>
            </ul>
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Intentar maneras de re enmarcar su puesto y su propósito dentro de
            la organización.
          </li>
          <li>
            - Oportunidades de desarrollo profesional, reconocimiento de logros,
            y el establecimiento de objetivos claros y alcanzables que alineen
            sus expectativas personales con las metas organizacionales.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Ineficaz;
