import React, { useEffect, useState } from "react";
import{
Row,
Col,
Table,
Card,
Button,
Skeleton,
} from 'antd';
import 'antd/dist/reset.css';
import {
    FileExcelOutlined
} from '@ant-design/icons';
import Api from '../../../Api';
import { gql, useQuery , useMutation} from '@apollo/client';
import { tokenDatos } from '../../../conf/token';
import { ReporteEmpresa } from '../../../conf/mutation';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import * as WebDataRocksReact from 'react-webdatarocks';
import TableRenderers from 'react-pivottable/TableRenderers';
import createPlotlyComponent from 'react-plotly.js/factory';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

// create Plotly React component via dependency injection
const Plot = createPlotlyComponent(window.Plotly);

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);



const Reporte = () =>{

    const [RptAvances] = useMutation(ReporteEmpresa)
    const [loading, setLoading] = useState(true)
    const [dataLogin, setDataLogin] = useState(tokenDatos())
    const [dataReporte, setDataReporte] = useState([])
    const [dataJson, setDataJson ] = useState([
        {
          "Product": "Apple",
          "Price": 2.50
        },
        {
          "Product": "Cherry",
          "Price": 5.25
        }
      ])
    //const PlotlyRenderers = createPlotlyRenderers(Plot);
    
    const [state, setState] = useState([]);

    const columns = [
        
        {
            title: 'CODEMP',
            dataIndex: 'CODEMP',
            key: 'CODEMP',  
          
        },
        {
            title: 'ENCNO',
            dataIndex: 'ENCNO',
            key: 'ENCNO', 
     
        },
        {
            title: 'NOMBRE',
            dataIndex: 'NOMBRE',
            key: 'NOMBRE', 
            width: 150
        },
        {
            title: 'REGION',
            dataIndex: 'REGION',
            key: 'REGION',
        },
        {
            title: 'SUCURSAL',
            dataIndex: 'SUCURSAL',
            key: 'SUCURSAL', 
          
        },
        {
            title: 'Direccion y objetivos',
            children: [
                {
                    title: 'Sé lo que se espera de mi',
                    dataIndex: '1',
                    key: '1', 
                  
                },
                {
                  title: 'Herramientas',
                  dataIndex: '2',
                  key: '2', 
            
                },
                {
                    title: 'Objetivos alcanzables',
                    dataIndex: '3',
                    key: '3', 
                },
                {
                    title: 'Organizacion',
                    dataIndex: '4',
                    key: '4', 
                },
                {
                    title: 'Comunicacion',
                    dataIndex: '5',
                    key: '5', 
                },
                {
                    title: 'Evaluacion de desempeno',
                    dataIndex: '6',
                    key: '6', 
                },
            ]
        },
        {
            title: 'Direccion y objetivos',
            dataIndex: 'Direcc',
            key: 'Direcc', 
          
        },
        {
            title: 'Incentivos y Motivacion',
            children: [
                {
                    title: 'Entrenamiento',
                    dataIndex: '7',
                    key: '7', 
                  
                },
                {
                  title: 'Ingresos Justos',
                  dataIndex: '8',
                  key: '8', 
            
                },
                {
                    title: 'Compromiso',
                    dataIndex: '9',
                    key: '9', 
                },
                {
                    title: 'Cumplimiento de equipo',
                    dataIndex: '10',
                    key: '10', 
                },
                {
                    title: 'Resolucion de Conflictos',
                    dataIndex: '11',
                    key: '11', 
                },
                {
                    title: 'Desarrollo profesional',
                    dataIndex: '12',
                    key: '12', 
                },
            ]
        },
        {
            title: 'Incentivos y motivación',
            dataIndex: 'Incent',
            key: 'Incent', 
          
        },
        {
            title: 'Pertenencia',
            children: [
                {
                    title: 'Revision de progreso',
                    dataIndex: '13',
                    key: '13', 
                  
                },
                {
                  title: 'Optimista sobre futuro',
                  dataIndex: '14',
                  key: '14', 
            
                },
                {
                    title: 'Amistades',
                    dataIndex: '15',
                    key: '15', 
                },
                {
                    title: 'Trabajo importante',
                    dataIndex: '16',
                    key: '16', 
                },
                {
                    title: 'Interes por mi persona',
                    dataIndex: '17',
                    key: '17', 
                },
             
            ]
        },
        {
            title: 'Pertenencia',
            dataIndex: 'Perte',
            key: 'Perte', 
          
        },
        {
            title: 'Autorrrealizacion',
            children: [
                {
                    title: 'Misión importante',
                    dataIndex: '19',
                    key: '19', 
                  
                },
                {
                  title: 'Independencia en el trabajo',
                  dataIndex: '20',
                  key: '20', 
            
                },
                {
                    title: 'Reconocimiento',
                    dataIndex: '21',
                    key: '21', 
                },
                {
                    title: 'Desarrollo personal',
                    dataIndex: '22',
                    key: '22', 
                },
                {
                    title: 'Valoración de opiniones',
                    dataIndex: '23',
                    key: '23', 
                },
                {
                    title: 'Aprendizaje y crecimiento',
                    dataIndex: '24',
                    key: '24', 
                },
            ],
        },
        {
            title: 'Autorrealización',
            dataIndex: 'Auto',
            key: 'Auto', 
          
        },
    ];
    const exportarExcel = () => {
        const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Reporte');
      worksheet.columns = [
          { header: 'Código empleado', key: 'CODEMP', width: 20 },
          { header: 'Nombre', key: 'NOMBRE', width: 40 },
          { header: 'Sucursal', key: 'SUCURSAL', width: 25 },
          { header: 'Región', key: 'REGION', width: 25 },
          { header: 'Puesto', key: 'PUESTO', width: 35 },
          { header: 'Genero', key: 'GENERO', width: 35 },
          { header: 'Sé lo que se espera de mi', key: '1', width: 15 },
          { header: 'Herramientas', key: '2', width: 15 },
          { header: 'Objetivos alcanzables', key: '3', width: 15 },
          { header: 'Organizacion', key: '4', width: 15 },
          { header: 'Comunicacion', key: '5', width: 15 },
          { header: 'Evaluacion de desempeno', key: '6', width: 15 },
          { header: 'Direccion y objetivos', key: 'Direcc', width: 15 },
          { header: 'Entrenamiento', key: '7', width: 15 },
          { header: 'Ingresos Justos', key: '8', width: 15 },
          { header: 'Compromiso', key: '9', width: 15 },
          { header: 'Cumplimiento de equipo', key: '10', width: 15 },
          { header: 'Resolucion de Conflictos', key: '11', width: 15 },
          { header: 'Desarrollo profesional', key: 'Incent', width: 15 },
          { header: 'Revision de progreso', key: '12', width: 15 },
          { header: 'Optimista sobre futuro', key: '13', width: 15 },
          { header: 'Amistades', key: '14', width: 15 },
          { header: 'Trabajo importante', key: '15', width: 15 },
          { header: 'Interes por mi persona', key: '16', width: 15 },
          { header: 'Pertenencia', key: 'Perte', width: 15 },
          { header: 'Misión importante', key: '17', width: 15 },
          { header: 'Independencia en el trabajo', key: '20', width: 15 },
          { header: 'Reconocimiento', key: '21', width: 15 },
          { header: 'Desarrollo personal', key: '22', width: 15 },
          { header: 'Valoración de opiniones', key: '23', width: 15 },
          { header: 'Aprendizaje y crecimiento', key: '24', width: 15 },
          { header: 'Autorrealización', key: 'Auto', width: 15 },
      ];
      
      dataReporte.map((item, index2) => (
        worksheet.addRow(item)
       
      ))
      workbook.xlsx.writeBuffer().then(function (data) {
        var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        saveAs(blob, 'reporte.xlsx');
      })
    }
    const cargarData = async ()=>{
        try{
            const { data } = await RptAvances({
              variables: {
                input: { 
                    ENC:  String(dataLogin.ENCUESTANO),
                    EMP:  String(dataLogin.CODIGOEMPRESA)
                },
              },
            })
          
            console.log(JSON.parse(data.repgeneral.mensaje))
            let dataJson = JSON.parse(data.repgeneral.mensaje)
          
            setDataReporte(dataJson.respuesta)
            console.log(JSON.stringify(dataJson.respuesta))
            setLoading(false)
            /*let op = []
            dataJson.respuesta.map((item) => (
                op.push(
                    {
                        CODEMP: item.CODEMP,
                        ENCNO: 1,
                        NOMBRE: item.NOMBRE,
                        REGION: item.REGION,
                        SUCURSAL: item.SUCURSAL,
                        PUESTO: item.PUESTO,
                        GENERO: item.GENERO,
                        Direcc: (Math.floor(Math.random()*(10-1+1) + 1) / precision),
                        Incent: (Math.floor(Math.random()*(10-1+1) + 1) / precision),
                        Perte: (Math.floor(Math.random()*(10-1+1) + 1) / precision),
                        Auto:(Math.floor(Math.random()*(10-1+1) + 1) / precision),
                        
                    }
                )
                
            ))
            /*
            1: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        2: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        3: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        4: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        5: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        6: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        7: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        8: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        9: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        10: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        11: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        12: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        13: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        14: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        15: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        16: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        17: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        18: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        19: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        20: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        21: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        22: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        23: Math.floor((Math.random() * (10 - 1 + 1)) + 1),
                        24: Math.floor((Math.random() * (10 - 1 + 1)) + 1),*/
            //console.log('resp RPT:',  op)
           
            
          
            //console.log(dataTotalResp)
          }catch(ex){
            console.log(ex)
          }
          
    }
   
    useEffect(() => {
        cargarData();
        //console.log(data)
    }, [])
    return(
        <>
            <div className="headline-2 mb-3">Reporte</div>
            {
            
            (loading )?
            (<Skeleton></Skeleton>):
            (
                <Card>
                  
                            {/*<PivotTableUI
                                data={dataReporte}
                                //renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                                onChange={(s) => {
                                    setState(s);
                                }}
                                {...state}
                            />*/}
                                {/*
                                <Table 

                                    loading={loadingData} 
                                    columns={columns} 
                                    dataSource={dataReporte} 
                                    scroll={{
                                    x: 5000,
                                    y: 500,
                                    }}
                                    bordered
                                    pagination={{
                                        pageSize: 10,
                                    }}
                                />*/}
                                <WebDataRocksReact.Pivot 
                                    width="100%"
                                    height="650px"
                                    toolbar={true} 
                                    report={{ 
                                        dataSource: { data:  dataReporte},
                                        
                                        slice: {
                                            rows: [
                                              {
                                                uniqueName: 'SUCURSAL',
                                              },
                                             
                                            ],
                                            measures: [
                                              {
                                                uniqueName: 'DIRECCIONOBJETIVO',
                                                aggregation: 'average',
                                                format: "claveFormato"
                                                
                                              },
                                              {
                                                uniqueName: 'INSENTIVOMOTIVACION',
                                                aggregation: 'average',
                                                format: "claveFormato"
                                              },
                                              {
                                                uniqueName: 'PERTENENCIA',
                                                aggregation: 'average',
                                                format: "claveFormato"
                                              },
                                              {
                                                uniqueName: 'AUTORREALIZACION',
                                                aggregation: 'average',
                                                format: "claveFormato"
                                              },
                                            ],
                                            
                                          }, 
                                          formats: [
                                            {
                                                name: 'claveFormato',
                                                decimalPlaces: 2,
                                                
                                            }
                                        ]
                                    }}
                                 
                                  
                                />
                             

                    </Card>
   
            )
        }
      </>
    );
}
export default Reporte;