import React from "react";
import { Badge } from "antd";
const Sobrecarga = () => {
  return (
    <Badge.Ribbon text="Sobrecarga Laboral" color="green">
      <div style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
        {/* Sección: Colaboradores con Sobrecarga Laboral */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Colaboradores con Sobrecarga Laboral
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Se describe como personas que valoran su rol dentro de la
            organización y sienten una gran satisfacción con sus logros
            personales. Sin embargo, enfrentan una cantidad considerable de
            responsabilidades y dificultades para cumplir con todas sus
            atribuciones, lo que está comenzando a generar niveles insostenibles
            de cansancio.
          </li>
          <li style={{ marginBottom: "10px" }}>
            - En el contexto de esta prueba, un colaborador sobrecargado cuenta
            con:
            <ul
              style={{
                listStyleType: "circle",
                paddingLeft: "40px",
                marginTop: "5px",
              }}
            >
              <li>Nivel alto de cansancio emocional</li>
              <li>Nivel alto de realización personal</li>
            </ul>
          </li>
        </ul>

        {/* Espacio entre secciones */}
        <div style={{ marginTop: "20px" }}></div>

        {/* Sección: Mejores prácticas */}
        <h3 style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Mejores prácticas
        </h3>
        <ul style={{ listStyleType: "none", paddingLeft: "0", marginTop: "0" }}>
          <li style={{ marginBottom: "10px" }}>
            - Se recomienda hacer cambios en las condiciones laborales de estos
            colaboradores, sino es probable que se observe un aumento en los
            niveles de despersonalización y, eventualmente, una disminución en
            los niveles de realización personal.
          </li>
          <li>
            - De ser necesario se pueden generar procesos para delegar
            responsabilidades, programas para sucesión y apoyo, sistematización
            de responsabilidades.
          </li>
        </ul>
      </div>
    </Badge.Ribbon>
  );
};

export default Sobrecarga;
